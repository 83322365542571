import React from 'react';
import styles from '../styles/Header.module.css';
import { NavLink } from 'react-router-dom';

export const Header = () => {
  return (
    <header className={styles.header}>
      <nav className={`${styles.nav} container`}>
        <NavLink className={styles.logo} to="/" end>
          {/* <img src={logotipo} className={styles.iconVet} alt="logo" /> */}
        </NavLink>

        <NavLink
          activeStyle={{ color: 'white' }}
          className={styles.login}
          exact
          to="/"
        >
          <p
            style={{
              fontSize: '100%',
              fontWeight: 'bold',
              fontFamily: 'Nunito',
              margin: '0 auto',
            }}
          >
            Login
          </p>
        </NavLink>

        {/* <NavLink
          activeStyle={{ color: 'white' }}
          className={styles.login}
          exact
          to="/Cadastrar"
        >
          <p
            style={{
              fontSize: '100%',
              fontWeight: 'bold',
              fontFamily: 'Nunito',
              margin: '0 auto',
            }}
          >
            Cadastrar
          </p>
        </NavLink> */}

        <NavLink
          activeStyle={{ color: 'white' }}
          className={styles.login}
          exact
          to="/RecuperarSenha"
        >
          <p
            style={{
              fontSize: '100%',
              fontWeight: 'bold',
              fontFamily: 'Nunito',
              margin: '0 auto',
            }}
          >
            Recuperar Senha
          </p>
        </NavLink>
      </nav>
    </header>
  );
};
export default Header;
