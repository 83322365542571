import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const QuotationEdit = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const emailAcesso = props.location.state.emailAcesso;

  const idRegistro = props.location.state.idRegistro;

  const [itensQuotation, setItensQuotation] = useState([]);
  const [priceInfo, setPriceInfo] = useState([]);
  const [preco, setPreco] = useState([]);
  const [obsFornecedor, setObsFornecedor] = useState([]);
  const [infoSupplier, setInfoSupplier] = useState([]);

  useEffect(() => {
    async function infoSupplier() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/FornecedorCotacao?email=${emailAcesso}&idCotacao=${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setInfoSupplier(data);
        });
    }
    infoSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idFornecedor = infoSupplier.id;

  useEffect(() => {
    async function cotacaoItens() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/CotacaoItens/${idRegistro}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setItensQuotation(data);
        });
    }
    cotacaoItens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function priceItens() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/Precos?idFornecedor=${infoSupplier.id}&idCotacao=${id}&idItem=${idRegistro}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setPriceInfo(data);
          setPreco(data ? data.preco?.toFixed(2) : 0);
          setObsFornecedor(data ? data.observacao : '');
        });
    }
    priceItens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoSupplier]);

  const handlePriceChange = (event) => {
    setPreco(event.target.value);
  };

  const handleObsChange = (event) => {
    setObsFornecedor(event.target.value);
  };

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apicw/Precos?idCotacao=${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        id: priceInfo ? priceInfo.id : 0,
        idfornecedor: infoSupplier.id,
        idcotacao: priceInfo
          ? priceInfo.idcotacao
          : itensQuotation[0].idCotacao,
        iditem: priceInfo ? priceInfo.iditem : itensQuotation[0].id,
        preco: (preco.replace && preco?.replace(',', '.')) || preco,
        observacao: obsFornecedor,
      }),
    }).then(() => {
      history.goBack();
    });
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '37.5%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <h2
        style={{
          color: '#0d214f',
          marginTop: '30px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        Cotação
      </h2>
      <div
        style={{
          marginTop: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Descrição:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {itensQuotation[0]?.descricao}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {' '}
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Código de Barras:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {itensQuotation[0]?.codigoBarras}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Quantidade:
          </p>
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation[0]?.quantidade}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            {' '}
            Unidade:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation[0]?.unidade}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Observação:
          </p>
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation[0]?.observacao}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#0d214f',
            }}
          >
            {' '}
            Preço: R$
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '100px',
              borderRadius: '5px',
              textAlign: 'right',
              paddingRight: '10px',
            }}
            type="number"
            value={preco}
            onChange={handlePriceChange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            {' '}
            Observação do Fornecedor:
          </p>
        </div>
        <p>
          <textarea
            style={{
              marginTop: '5px',
              borderRadius: '5px',
              paddingLeft: '10px',
              paddingTop: '5px',
            }}
            name="observacao"
            id="observacao"
            cols="30"
            rows="5"
            value={obsFornecedor}
            onChange={handleObsChange}
          ></textarea>
        </p>
      </div>
      <div style={{ margin: '10px auto' }}>
        <Button1
          style={{ display: 'inline-block', width: '47%' }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button1>
        <Button1
          style={{
            display: 'inline-block',
            marginLeft: '10px',
            width: '47%',
          }}
          onClick={handleSave}
        >
          Salvar
        </Button1>
      </div>
    </div>
  );
};

export default QuotationEdit;
