import React, { useState } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const TutorPage = (props) => {
  const history = useHistory();

  const location = useLocation();

  const savedData = JSON.parse(localStorage.getItem('dadosTutor'));

  const nomeTutor2 = savedData?.nomeTutor;
  const cpf2 = savedData?.cpf;
  const nascimentoTutor2 = savedData?.nascimentoTutor;
  const email2 = savedData?.email;
  const telefone2 = savedData?.telefone;

  const [nomeTutor, setNomeTutor] = useState(nomeTutor2 || '');
  const [cpf, setCpf] = useState(cpf2 || '');
  const [nascimentoTutor, setNascimentoTutor] = useState(
    nascimentoTutor2 || '',
  );
  const [email, setEmail] = useState(email2 || '');
  const [telefone, setTelefone] = useState(telefone2 || '');

  const handleNome = (event) => {
    setNomeTutor(event.target.value);
  };

  const handleCpf = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');

    const maxLength = 11;
    const truncatedValue = numericValue.slice(0, maxLength);

    const formattedCpf = truncatedValue.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4',
    );

    setCpf(formattedCpf);
  };

  const handleNascimento = (event) => {
    setNascimentoTutor(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleTelefone = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');

    const maxLength = 11;
    const truncatedValue = numericValue.slice(0, maxLength);

    let formattedTelefone;

    if (truncatedValue.length <= 2) {
      formattedTelefone = `(${truncatedValue})`;
    } else if (truncatedValue.length <= 7) {
      formattedTelefone = `(${truncatedValue.slice(
        0,
        2,
      )}) ${truncatedValue.slice(2)}`;
    } else {
      formattedTelefone = `(${truncatedValue.slice(
        0,
        2,
      )}) ${truncatedValue.slice(2, 6)}-${truncatedValue.slice(6)}`;
    }
    setTelefone(formattedTelefone);
  };

  function handleNext() {
    const dataToSave = {
      nomeTutor,
      cpf,
      nascimentoTutor,
      email,
      telefone,
    };

    const jsonData = JSON.stringify(dataToSave);

    const apiUrl = 'https://www.vetnaweb.com.br/apiveus';

    const url = `${apiUrl}/Data/?data=${nascimentoTutor.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      '$3/$2/$1',
    )}`;

    const url2 = `${apiUrl}/Cpf/?cpf=${cpf}`;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
    };

    localStorage.setItem('dadosTutor', jsonData);

    const promise1 = fetch(url, {
      method: 'GET',
      headers: headers,
    }).then((response) => response.json());

    const promise2 = fetch(url2, {
      method: 'GET',
      headers: headers,
    }).then((response) => response.json());

    Promise.all([promise1, promise2])
      .then((results) => {
        const result1 = results[0];
        const result2 = results[1];

        if (result1 === 0 && result2 === 0) {
          history.push({
            pathname: '/PedidoDeExame/Animal',
          });
        } else if (result1 === -1) {
          alert('Favor preencher a data de nascimento corretamente.');
        } else if (result2 === -1) {
          alert('CPF inválido.');
        }
      })
      .catch((error) => {
        console.error('Erro na solicitação GET:', error);
      });
  }

  function handleBack() {
    history.push({
      pathname: '/',
    });
  }

  return (
    <div style={{ marginBottom: '20%', textAlign: 'right' }}>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Preencha a Solicitação de Exame
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>Tutor</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nome:
            </p>
            <input
              type="text"
              value={nomeTutor}
              onChange={handleNome}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              CPF:
            </p>
            <input
              type="text"
              value={cpf}
              onChange={handleCpf}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nascimento:
            </p>
            <input
              type="date"
              value={nascimentoTutor}
              onChange={handleNascimento}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              E-mail:
            </p>
            <input
              type="text"
              value={email}
              onChange={handleEmail}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Telefone:
            </p>
            <input
              type="text"
              value={telefone}
              onChange={handleTelefone}
              style={{ width: '300px' }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={handleBack}
        >
          Voltar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleNext}
        >
          Próximo
        </Button1>
      </div>
    </div>
  );
};

export default TutorPage;
