import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';

const EditAttendant = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { idAcesso } = props.location.state;

  const { id } = useParams();

  const [atendentesInfo, setAtendentesInfo] = useState('');

  useEffect(() => {
    async function AttendantInfo() {
      try {
        const response = await api.get(`/Atendentes?id=${id}`);
        setAtendentesInfo(response.data);
      } catch (error) {}
    }
    AttendantInfo();
  }, [id]);

  const [nomeAtendente, setNomeAtendente] = useState('');
  const [email, setEmail] = useState('');
  const [crmv, setCrmv] = useState('');

  useEffect(() => {
    if (atendentesInfo.nome) {
      setNomeAtendente(atendentesInfo.nome);
    }
  }, [atendentesInfo]);

  useEffect(() => {
    if (atendentesInfo.email) {
      setEmail(atendentesInfo.email);
    }
  }, [atendentesInfo]);

  useEffect(() => {
    if (atendentesInfo.crmv) {
      setCrmv(atendentesInfo.crmv);
    }
  }, [atendentesInfo]);

  const handleNome = (event) => {
    setNomeAtendente(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleCrmv = (event) => {
    setCrmv(event.target.value);
  };

  function handleSave() {
    fetch(
      `https://www.vetnaweb.com.br/apiveus/Atendentes?idParceiro=${idAcesso}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          id: atendentesInfo.id,
          // idCliente: 0,
          nome: nomeAtendente,
          email: email,
          crmv: crmv,
          tipoAcesso: 'M',
        }),
      },
    ).then(() => {
      alert('Salvo com sucesso!');
      history.goBack();
    });
  }

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
          color: '#0d214f',
        }}
      >
        Editar Atendente
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nome:
            </p>
            <input
              type="text"
              onChange={handleNome}
              style={{ width: '300px' }}
              value={nomeAtendente}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              E-mail:
            </p>
            <input
              type="text"
              onChange={handleEmail}
              style={{ width: '300px' }}
              value={email}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              CRMV:
            </p>
            <input
              type="text"
              onChange={handleCrmv}
              style={{ width: '300px' }}
              value={crmv}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleSave}
        >
          Salvar
        </Button1>
      </div>
    </div>
  );
};

export default EditAttendant;
