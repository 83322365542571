import React, { createContext, useState, useEffect } from 'react';
import { firebaseConnect } from '../services/firebaseConnection';
import { useHistory } from 'react-router-dom';

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
// eslint-disable-next-line no-unused-vars
import { getDatabase, ref, onValue, get, child, set } from 'firebase/database';
import { setCookie, parseCookies, destroyCookie } from 'nookies';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  firebaseConnect();
  // eslint-disable-next-line no-unused-vars
  const { vwpagestay, vwpagecond } = parseCookies();

  let anyState;
  let firebaseError;

  const [user, setUser] = useState(null);
  const [selectState, setSelectState] = useState(null);
  const [selectState2, setSelectState2] = useState('');
  const [selectState3, setSelectState3] = useState(null);
  const [loading, setLoading] = useState(true);
  const [conditionLoading, setConditionLoading] = useState(
    window.sessionStorage.getItem('vwpagecond') ? false : true,
  );
  const [logCompany, setLogCompany] = useState(null);
  const [logCompanyId, setLogCompanyId] = useState(null);
  const [logName, setLogName] = useState(null);
  const [logId, setLogId] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [error, setError] = useState(null);
  const [click, setClick] = useState(false);
  // eslint-disable-next-line eqeqeq
  const [stay, setStay] = useState(vwpagestay == 'true');
  const navigate = useHistory();

  const auth = getAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { vwpageauth } = parseCookies();

    if (vwpageauth) {
      await setUserFunc(vwpageauth, 0);
    } else {
      // User is signed out
      signOutFunc();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function verifyUserLoged() {
    const { vwpageauth } = parseCookies();

    if (!vwpageauth) {
      signOutFunc();

      anyState = false;
      return false;
    } else {
      anyState = true;
      return true;
    }
  }

  async function setUserFunc(dataLogin, identifier, google) {
    setLoading(true);
    const dbRef = ref(getDatabase());

    get(
      child(
        dbRef,
        `users/${identifier === 0 ? dataLogin : dataLogin.uid}/dados`,
      ),
    )
      .then((snapshot) => {
        const data = {
          uid: identifier === 0 ? dataLogin : dataLogin.uid,
          nome: snapshot.val().nome,
          avatarNull: snapshot.val().avatarNull,
          email: snapshot.val().email,
          idAvatar: !google ? snapshot.val().idAvatar : undefined,
          idAvatarGoogle: google ? snapshot.val().idAvatarGoogle : undefined,
          idParceiro: snapshot.val().idparceiro,
          idAvatarPet: snapshot.val().idAvatarPet,
          idDoc: snapshot.val().idDoc,
        };

        setCookie(
          undefined,
          'vwpageauth',
          identifier === 0 ? dataLogin : dataLogin.uid,
          stay ? { maxAge: 5 * 365 * 24 * 60 * 60 } : '',
        );
        setCookie(
          undefined,
          'vwpagestay',
          stay,
          stay ? { maxAge: 5 * 365 * 24 * 60 * 60 } : '',
        );
        window.sessionStorage.setItem('vwpagecond', 'false');

        setUser(data);
        setLoading(false);
        setConditionLoading(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }

  async function signIn(email, password, avatar) {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setUserFunc(userCredential.user, 1);
      })
      .catch((error) => {
        setLoading(false);
        firebaseError = error;
      });
    return firebaseError;
  }

  async function signUp(email, password, nome) {
    await createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setLoading(true);
        set(ref(getDatabase(), `/users/${userCredential.user.uid}/dados`), {
          nome: nome,
          email: email,
          idAvatar: null,
        })
          .then((user) => {
            setUserFunc(userCredential.user, 1);
          })
          .catch((error) => {
            setLoading(false);
          });
        navigate.push('/');
      })
      .catch((error) => {
        setLoading(false);
        firebaseError = error;
      });
    return firebaseError;
  }

  async function setLogged(checked) {
    if (checked) {
      setStay(true);
    } else {
      setStay(false);
    }
  }

  // salvar Avatar usuário
  async function salvarAvatar(avatar, uid) {}
  // salvar Avatar usuário Google
  async function salvarAvatarGoogle(avatar, uid) {}
  // salvar id Usuário
  async function salvarIdParceiro(idparceiro, uid) {}
  // salvar Documento usuário
  async function salvarDocumento(doc, uid) {}
  // salvar Avatar Animal
  async function salvarAvatarPet(avatarPet, uid) {}

  function getMessageError(errorCode) {
    switch (errorCode) {
      case 400:
        return 'Solicitação Inválida';
      case 401:
        return 'Não autorizado';
      case 402:
        return 'Pagamento necessário';
      case 403:
        return 'Proibido';
      case 404:
        return 'Não encontrado';
      case 405:
        return 'Método não permitido';
      case 406:
        return 'Não aceito';
      case 407:
        return 'Autenticação de Proxy Necessária';
      case 408:
        return 'Tempo de solicitação esgotado';
      case 409:
        return 'Conflito';
      case 410:
        return 'Perdido';
      case 411:
        return 'Duração necessária';
      case 412:
        return 'Falha de pré-condição';
      case 413:
        return 'Solicitação da entidade muito extensa';
      case 414:
        return 'Solicitação de URL muito Longa';
      case 415:
        return 'Tipo de mídia não suportado';
      case 416:
        return 'Solicitação de faixa não satisfatória';
      case 417:
        return 'Falha na expectativa';
      case 421:
        return 'Solicitação inválida';
      case 422:
        return 'Entidade não processável';
      case 423:
        return 'Bloqueado';
      case 424:
        return 'Falha na dependência';
      case 426:
        return 'Atualização de Protocolo necessária';
      case 428:
        return 'Pré-condição necessária';
      case 429:
        return 'Muitas Solicitações';
      case 431:
        return 'Solicitação da entidade muito extensa';
      case 451:
        return 'Indisponivel por Motivos Legais';
      case 499:
        return 'Tipo de mídia não suportado';
      case 500:
        return 'Erro Interno do Servidor';
      case 501:
        return 'Não implementado';
      case 502:
        return 'Porta de entrada ruim';
      case 503:
        return 'Serviço Indisponível';
      case 504:
        return 'Tempo limite da Porta de Entrada';
      case 505:
        return 'Versão HTTP não suportada';
      case 507:
        return 'Armazenamento Insuficiente';
      case 508:
        return 'Loop Detectado';
      case 510:
        return 'Não Estendido';
      case 511:
        return 'Autenticação de Rede Necessária';
      default:
        return 'Erro não Registrado';
    }
  }

  function getMessageByErrorCode(errorCode) {
    switch (errorCode) {
      case 'auth/wrong-password':
        return 'Senha incorreta';
      case 'auth/popup-closed-by-user':
        return 'Operação cancelada pelo usuário antes de ser finalizada.';
      case 'auth/timeout':
        return 'Não autorizado.';
      case 'auth/missing-email':
        return 'É necessário informar um E-mail.';
      case 'auth/operation-not-supported-in-this-environment':
        return 'Protocolo não Permitido';
      case 'auth/email-already-in-use':
        return 'E-mail já está em uso';
      case 'auth/invalid-email':
        return 'Digite um e-mail válido';
      case 'auth/weak-password':
        return 'Digite uma senha mais forte (mínimo 6 caracteres)';
      case 'auth/user-not-found':
        return 'Usuário não encontrado';
      case 'auth/email-already-exists':
        return 'O E-mail informado já está em uso por outro usuário.';
      case 'auth/invalid-display-name':
        return 'Insira seu nome';
      case 'auth/invalid-phone-number':
        return 'Telefone não informado corretamente';
      case 'auth/invalid-photo-url':
        return 'O Valor deve ser uma URL.';
      case 'auth/invalid-provider-data':
        return 'O providerData precisa ser uma matriz válida de objetos UserInfo.';
      case 'tooManyRequests':
        return 'Você fez muitas tentativas.';
      case 'auth/internal-error':
        return 'O Servidor encontrou um erro inesperado.';
      case 'auth/invalid-continue-uri':
        return 'O URL de confirmação precisa ser uma string de URL válida';
      case 'auth/uid-already-exists':
        return 'UID já utilizado por outro usuário.';
      default:
    }
  }

  function getMessageByErrorCodeAxios(errorCode) {
    switch (errorCode) {
      case 400:
        return 'Essa resposta significa que o servidor não entendeu a requisição pois está com uma sintaxe inválida.';
      case 401:
        return 'O cliente deve se autenticar para obter a resposta solicitada.';
      case 403:
        return 'O cliente não tem direitos de acesso ao conteúdo portanto o servidor está rejeitando dar a resposta.';
      case 404:
        return 'O servidor não pode encontrar o recurso solicitado.';
      case 405:
        return 'O método de solicitação é conhecido pelo servidor, mas foi desativado e não pode ser usado.';
      case 407:
        return 'É necessário que a autenticação seja feita por um proxy.';
      case 408:
        return 'O servidor gostaria de derrubar esta conexão em desuso.';
      case 409:
        return 'requisição em conflito com o estado atual do servidor.';
      case 410:
        return 'requisição em conflito com o estado atual do servidor.';
      case 411:
        return 'O servidor rejeitou a requisição porque o campo Content-Length do cabeçalho não está definido e o servidor o requer.';
      case 412:
        return 'O cliente indicou nos seus cabeçalhos pré-condições que o servidor não atende.';
      case 413:
        return 'A entidade requisição é maior do que os limites definidos pelo servidor; o servidor pode fechar a conexão ou retornar um campo de cabeçalho Retry-After.';
      case 414:
        return 'A URI requisitada pelo cliente é maior do que o servidor aceita para interpretar.';
      case 415:
        return 'O formato de mídia dos dados requisitados não é suportado pelo servidor, então o servidor rejeita a requisição.';
      case 416:
        return 'O trecho especificado pelo campo Range do cabeçalho na requisição não pode ser preenchido; é possível que o trecho esteja fora do tamanho dos dados da URI alvo.';
      case 421:
        return 'A requisição foi direcionada a um servidor inapto a produzir a resposta. Pode ser enviado por um servidor que não está configurado para produzir respostas para a combinação de esquema ("scheme") e autoridade inclusas na URI da requisição.';
      case 429:
        return 'O usuário enviou muitas requisições num dado tempo ("limitação de frequência").';
      case 500:
        return 'O servidor encontrou uma situação com a qual não sabe lidar.';
      case 501:
        return 'O método da requisição não é suportado pelo servidor e não pode ser manipulado. Os únicos métodos exigidos que servidores suportem (e portanto não devem retornar este código) são GET e HEAD.';
      case 502:
        return 'Esta resposta de erro significa que o servidor, ao trabalhar como um gateway a fim de obter uma resposta necessária para manipular a requisição, obteve uma resposta inválida.';
      case 503:
        return 'O servidor não está pronto para manipular a requisição. Causas comuns são um servidor em manutenção ou sobrecarregado.';
      case 504:
        return 'Esta resposta de erro é dada quando o servidor está atuando como um gateway e não obtém uma resposta a tempo.';

      default:
    }
  }

  // recuperar senha de usuario

  async function forgot(email) {
    await sendPasswordResetEmail(auth, email)
      .then(() => {})
      .catch((error) => {
        firebaseError = error;
      });
    return firebaseError;
  }

  async function storageUser(data) {
    if (stay) {
      window.localStorage.setItem('Auth_user', JSON.stringify(data));
    } else {
      window.localStorage.setItem('Auth_user', JSON.stringify(data));
      window.sessionStorage.setItem('Auth_user', JSON.stringify(data));
    }
  }

  async function signOutFunc() {
    signOut(auth)
      .then((response) => {})
      .catch((error) => {});

    destroyCookie(undefined, 'vwpagestay');
    destroyCookie(undefined, 'vwpageauth');
    setUser(null);
    navigate.push('/');
  }

  async function loginGoogle() {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        setLoading(true);

        set(ref(getDatabase(), `/users/${result.user.uid}/dados`), {
          nome: result.user.displayName,
          email: result.user.email,
          idAvatarGoogle: result.user.photoURL,
        })
          .then((user) => {
            setUserFunc(result.user, 1, true);
          })
          .catch((error) => {
            setLoading(false);
          });
        navigate.push('/');
      })
      .catch((error) => {
        firebaseError = error;
      });
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        signed: !!user,
        user: user,
        loading,
        conditionLoading,
        signUp,
        signIn,
        signOutFunc,
        loadingAuth,
        forgot,
        setLoading,
        storageUser,
        salvarAvatar,
        setUser,
        salvarAvatarPet,
        salvarDocumento,
        salvarAvatarGoogle,
        setLogged,
        getMessageByErrorCode,
        getMessageByErrorCodeAxios,
        getMessageError,
        salvarIdParceiro,
        error,
        setError,
        setLoadingAuth,
        click,
        setClick,
        loginGoogle,
        verifyUserLoged,
        anyState,
        firebaseError,
        logCompany,
        logCompanyId,
        logName,
        logId,
        setLogCompany,
        setLogCompanyId,
        setLogName,
        setLogId,
        selectState,
        setSelectState,
        selectState2,
        setSelectState2,
        selectState3,
        setSelectState3,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
