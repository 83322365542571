import React, { useState } from 'react';
import Input from '../../../components/Forms/Input';
import { Button1 } from '../../../components/Forms/FormItems';
import useForm from '../../../Hooks/useForm';
import { AuthContext } from '../../../contexts/AuthContext';
import Error from '../../../helper/Error';
import styles from '../../../styles/LoginForm.module.css';
import Spinner from 'react-bootstrap/Spinner';
import Stack from '@material-ui/core/Stack';
import { Alert } from '@material-ui/core';

const LoginCreate = () => {
  const username = useForm({});
  const email = useForm({});
  const password = useForm({});
  const {
    signUp,
    loadingAuth,
    getMessageByErrorCode,
    setClick,
    click,
    // setLoadingAuth,
  } = React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    signUp(email.value, password.value, username.value).then((value) => {
      setClick(true);

      value && setError(` - ${value.code}`);
      value && setErrorMessage(getMessageByErrorCode(value.code));
    });
  }

  React.useEffect(() => {
    setError(null);
    setClick(false);

    window.scrollTo(0, 0);

    return () => {
      setError(null);
      setClick(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apiveus/Clientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        // id: 0,
        nomeEmpresa: null,
        emailPrincipal: email.value,
        telefone1: null,
        telefone2: null,
        // cadastroem:
        bloqueadoem: null,
        idlicenca: 1,
        cnpj: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        cep: null,
        liberaparcial: null,
        aceiteTermos: null,
      }),
    }).then(() => {
      // history.goBack();
    });
  }

  return (
    <section className="animeLeft">
      <h1 className="title">Cadastre-se</h1>
      <form
        onSubmit={handleSubmit}
        onChange={() => {
          setClick(false);
          setErrorMessage(null);
          setError(null);
        }}
      >
        <Input label="Usuário" type="text" name="username" {...username} />
        <Input label="E-mail" type="email" name="email" {...email} />
        <Input label="Senha" type="password" name="password" {...password} />
        {/* <p style={{ fontWeight: 'bold' }}>Como vai usar essa conta?</p>
        <select
          name=""
          id=""
          style={{
            marginTop: '5px',
            width: '100%',
            height: '50px',
            borderRadius: '5px',
            paddingLeft: '10px',
            marginBottom: '20px',
          }}
        >
          <option value="C">Comprador</option>
          <option value="F">Fornecedor</option>
        </select> */}
        {loadingAuth ? (
          <Button1 variant="success" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Carregando...
            </Stack>
          </Button1>
        ) : (
          <Button1 onClick={handleSave}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Cadastrar
            </Stack>
          </Button1>
        )}
      </form>
      <div style={{ margin: '2.5% 0 0' }}>
        {
          // eslint-disable-next-line eqeqeq
          click && error == undefined && email.value !== '' ? (
            <Alert>
              <h6>Cadastro criado com Sucesso</h6>
            </Alert>
          ) : null
        }
        <div className={styles.containerError}>
          <Error error={errorMessage} />
        </div>
      </div>
    </section>
  );
};

export default LoginCreate;
