import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const EditItemQuotation = (props) => {
  const { id } = useParams();

  const idFornecedor = props.location.state.idFornecedor;

  const idCotacao = props.location.state.idCotacao;

  const idItem = props.location.state.idItem;

  const history = useHistory();

  const [itensQuotation, setItensQuotation] = useState([]);
  const [priceItem, setPriceItem] = useState([]);
  const [preco, setPreco] = useState([]);

  useEffect(() => {
    async function cotacaoItens() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/CotacaoItens/${idItem}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setItensQuotation(data[0]);
          setPreco(data[0].preco);
        });
    }
    cotacaoItens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function priceItens() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/Precos?idFornecedor=${idFornecedor}&idCotacao=${idCotacao}&idItem=${idItem}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setPriceItem(data);
          setPreco(data ? data.preco?.toFixed(2) : 0);
        });
    }
    priceItens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePriceChange = (event) => {
    setPreco(event.target.value);
  };

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apicw/Precos?idCotacao=${idCotacao}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        id: priceItem.id,
        idfornecedor: idFornecedor,
        idcotacao: idCotacao,
        iditem: idItem,
        preco: (preco.replace && preco?.replace(',', '.')) || preco,
        observacao: priceItem.observacao,
      }),
    }).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Editar Preço da Cotação
      </h1>
      <div
        style={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Descrição:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {itensQuotation.descricao}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          {' '}
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Código de Barras:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {itensQuotation.codigoBarras}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Quantidade:
          </p>
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation.quantidade}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            {' '}
            Unidade:
          </p>{' '}
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation.unidade}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Observação:
          </p>
          <p style={{ fontWeight: 'bold', marginLeft: '5px' }}>
            {' '}
            {itensQuotation.observacao}
          </p>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#0d214f',
            }}
          >
            {' '}
            Preço: R$
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '100px',
              borderRadius: '5px',
              textAlign: 'right',
              paddingRight: '10px',
            }}
            type="number"
            value={preco}
            onChange={handlePriceChange}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            {' '}
            Observação do Fornecedor:
          </p>
        </div>
        <p>
          <textarea
            style={{
              marginTop: '5px',
              borderRadius: '5px',
              paddingLeft: '10px',
              paddingTop: '5px',
            }}
            name="observacao"
            id="observacao"
            cols="30"
            rows="5"
            value={itensQuotation.observacao}
            disabled
          ></textarea>
        </p>
        <div style={{ margin: '10px auto' }}>
          <Button1
            style={{ display: 'inline-block', width: '47%' }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={handleSave}
          >
            Salvar
          </Button1>
        </div>
      </div>
    </div>
  );
};

export default EditItemQuotation;
