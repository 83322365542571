import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useParams } from 'react-router-dom';

const ExamResult = (props) => {
  const [resultadoExame, setResultadoExame] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    async function fetchPDF() {
      try {
        const response = await api.get(`/laudos?recipiente=${id}`);
        setResultadoExame(response.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    fetchPDF();
  }, [id]);

  useEffect(() => {
    async function fetchPDF() {
      try {
        const response = await api.get(`/laudos?recipiente=${id}`);
        setResultadoExame(response.data);

        // Abrir o PDF automaticamente em uma nova guia
        const newWindow = window.open();
        newWindow.document.write(
          `<iframe src="data:application/pdf;base64,${response.data}" width="100%" height="1000px"></iframe>`,
        );
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }

    fetchPDF();
  }, [id]);

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Resultado Exame
      </h2>
      {loading ? (
        <p style={{ textAlign: 'center' }}>Carregando...</p>
      ) : resultadoExame ? (
        <></>
      ) : (
        <div
          style={{
            textAlign: 'center',
            marginTop: '30px',
            fontWeight: 'bold',
            marginBottom: '30px',
          }}
        >
          <p>
            O resultado ainda não está pronto. Favor tentar novamente mais
            tarde.
          </p>
        </div>
      )}
    </div>
  );
};

export default ExamResult;
