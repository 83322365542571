import React, { useState, useEffect, useRef } from 'react';
import { Button1 } from '../../components/Forms/FormItems';

import './OrdersBySupplier.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import style2 from '../../styles/GlobalCss.module.css';
import { Link } from 'react-router-dom';

import { useDownloadExcel } from 'react-export-table-to-excel';
import { useParams } from 'react-router-dom';

const OrdersBySupplier = (props) => {
  const [relatorio, setRelatorio] = useState([]);

  const tableref = useRef(null);

  const { id } = useParams();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableref.current,
    filename: 'pedidosporfornecedor',
    sheet: 'relatorio',
  });

  useEffect(() => {
    async function relatorio() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/ResultadoCotacao03?idcotacao=${id}
        `,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setRelatorio(data);
        });
    }
    relatorio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div style={{ margin: '0 auto' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          marginBottom: '-10px',
        }}
      >
        <Link
          className={`${style2.link} no-print`}
          onClick={handlePrint}
          style={{ margin: '40px' }}
        >
          <div
            style={{
              justifyContent: 'center',
              height: 'max-content',
              alignSelf: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPrint}
              className={style2.iconStyle4}
              title="Imprimir"
            />
          </div>
        </Link>
        <Link
          className={`${style2.link} no-print`}
          onClick={onDownload}
          style={{ margin: '40px' }}
        >
          <div
            style={{
              justifyContent: 'center',
              height: 'max-content',
              alignSelf: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faFileExcel}
              className={style2.iconStyle4}
              title="Gerar XLS"
            />
          </div>
        </Link>
      </div>

      <div style={{ paddingBottom: '5%' }}>
        <table className="estilizada2" id="tableQuotation" ref={tableref}>
          <thead>
            <tr>
              <th style={{ fontSize: '14px' }}>Fornecedor</th>
              <th style={{ fontSize: '14px' }}>Descrição</th>
              <th style={{ fontSize: '14px' }}>Qtde</th>
              <th style={{ fontSize: '14px' }}>Un</th>
              <th style={{ fontSize: '14px' }}>Obs Fornecedor</th>
              <th style={{ fontSize: '14px' }}>Preço</th>
            </tr>
          </thead>
          <tbody>
            {relatorio &&
              relatorio?.map((rel, index) => (
                <tr key={index}>
                  <td style={{ fontSize: '12px' }}>{rel.fornecedor}</td>
                  <td style={{ fontSize: '12px' }}>{rel.descricao}</td>
                  <td style={{ fontSize: '12px' }}>{rel.quantidade}</td>
                  <td style={{ fontSize: '12px' }}>{rel.unidade}</td>
                  <td style={{ fontSize: '12px' }}>{rel.ObsPreco}</td>
                  <td style={{ fontSize: '12px' }}>{rel.Preco.toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrdersBySupplier;
