import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Button1 } from '../../components/Forms/FormItems';
import api from '../../services/api';

const QuotationItens = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const [infoClient, setInfoClient] = useState([]);
  const [itens, setItens] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState({});

  const toggleOpcoes = (index) => {
    const novasOpcoesAbertas = {};

    Object.keys(opcoesAbertas).forEach((key) => {
      novasOpcoesAbertas[key] = false;
    });

    novasOpcoesAbertas[index] = !opcoesAbertas[index];

    setOpcoesAbertas(novasOpcoesAbertas);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tabelaElement = document.getElementById('tableQuotation');
      if (tabelaElement && !tabelaElement.contains(event.target)) {
        setOpcoesAbertas({});
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function infoClient2() {
      try {
        const response = await api.get(`/Cotacao/${id}`);
        setInfoClient(response.data);
      } catch (error) {}
    }
    infoClient2();
  }, [id]);

  const idParceiro = infoClient.idCliente;

  useEffect(() => {
    if (idParceiro) {
      async function itens() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/CotacaoItens?idParceiro=${idParceiro}&idCotacao=${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setItens(data);
          });
      }
      itens();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoClient]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;

      const content = base64String.split(',')[1];

      history.push(`/VisualizacaoCSV/file=${encodeURIComponent(content)}`, {
        idCotacao: infoClient.id,
        idCliente: infoClient.idCliente,
      });
    };
    reader.readAsDataURL(file);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div>
      <h2
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
        }}
      >
        Itens da Cotação
      </h2>
      <h3
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
        }}
      >
        {infoClient.descricao} - válida até{' '}
        {infoClient?.validade
          ?.split('T')[0]
          .split('-')
          .join('/')
          .replace(/^(\d{4})\/(\d{2})\/(\d{2}).*$/, '$3/$2/$1')}{' '}
        às {infoClient?.horario?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2h')}
        <Link
          to={{
            pathname: `/AdicionarItemCotacao/${id}`,
            state: {},
          }}
          style={{
            display: 'inline-block',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              height: 'max-content',
              alignSelf: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={12}
              style={{
                marginBottom: '5px',
                marginLeft: '20px',
                color: '#0d214f',
                fontSize: '24',
              }}
            />
          </div>
        </Link>
      </h3>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileUpload}
      />
      <Button1
        onClick={() => document.getElementById('fileInput').click()}
        style={{ marginLeft: 'auto', marginRight: '5%', marginBottom: '-15px' }}
      >
        Importar CSV
      </Button1>
      <div style={{ paddingBottom: '10%' }}>
        <table
          className="estilizada2"
          id="tableQuotation"
          style={{ whiteSpace: 'nowrap' }}
        >
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Código de Barras</th>
              <th>Quantidade</th>
              <th>Unidade</th>
              <th>Observação</th>
            </tr>
          </thead>
          <tbody>
            {itens.map((tabela, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                }}
                onMouseEnter={() => handleRowEnter(index)}
                onMouseLeave={handleRowLeave}
              >
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {tabela.descricao}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {tabela.codigoBarras}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {tabela.quantidade}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {tabela.unidade}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {tabela.observacao}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {' '}
                  <FontAwesomeIcon
                    onClick={() => toggleOpcoes(index)}
                    style={{ cursor: 'pointer' }}
                    icon={faEllipsisV}
                  />
                </td>
                {opcoesAbertas[index] && (
                  <ul
                    style={{
                      marginTop: '37px',
                      marginLeft: '-72.5px',
                      padding: '10px',
                      borderRadius: '4px',
                      position: 'absolute',
                      zIndex: '999',
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <li
                      style={{ cursor: 'pointer', marginBottom: '5px' }}
                      onClick={() => {
                        history.push(`/EditarCotacaoItem/${tabela.id}`, {
                          idCliente: tabela.idCliente,
                        });
                      }}
                    >
                      Editar
                    </li>
                    <li
                      style={{ cursor: 'pointer', marginBottom: '5px' }}
                      onClick={() => {
                        history.push(`/ExcluirItemCotacao/${tabela.id}`, {
                          idCliente: tabela.idCliente,
                        });
                      }}
                    >
                      Excluir
                    </li>
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/Cotacoes/${tabela.id}`, {
                          idCliente: tabela.idCliente,
                        });
                      }}
                    >
                      Ver Cotações
                    </li>
                  </ul>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default QuotationItens;
