import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';

const ConfigureWhatsApp = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [carregado2, setCarregado2] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);

  const [infoConfig, setInfoConfig] = useState([]);
  const [gerarQrCode, setGerarQrCode] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState(false);

  const [previousQrCodeValue, setPreviousQrCodeValue] = useState(
    gerarQrCode.value,
  );

  useEffect(() => {
    if (
      previousQrCodeValue &&
      gerarQrCode.value === undefined &&
      gerarQrCode.connected === true
    ) {
      const timer = setTimeout(() => {
        history.push('/');
      }, 2000);

      return () => clearTimeout(timer);
    }

    setPreviousQrCodeValue(gerarQrCode.value);
  }, [gerarQrCode.value, previousQrCodeValue, history]);

  const toggleOpcoes = () => {
    setOpcoesAbertas(!opcoesAbertas);
  };

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpcoesAbertas(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        setOpcoesAbertas(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idAcesso = clinicalList.idAcesso;

  useEffect(() => {
    if (carregado) {
      async function config() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/ConfigWhatsApp?idParceiro=${idAcesso}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setInfoConfig(data);
            setCarregado2(true);
          });
      }
      config();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const instancia = infoConfig?.instancia;

  const token = infoConfig?.token;

  const [qrCodeLoaded, setQrCodeLoaded] = useState(false);

  function handleAtualizarQrCode() {
    if (!gerarQrCode.connected) {
      fetch(
        `https://api.plugzapi.com.br/instances/${instancia}/token/${token}/qr-code/image`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setGerarQrCode(data);
          setQrCodeLoaded(true);
        });
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleAtualizarQrCode();
    }, 3000);

    return () => clearInterval(interval);
  }, [qrCodeLoaded]);

  useEffect(() => {
    if (carregado2) {
      async function gerar() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://api.plugzapi.com.br/instances/${instancia}/token/${token}/qr-code/image`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setGerarQrCode(data);
          });
      }
      gerar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado2]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        width: '100%',
        marginTop: '50px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }} ref={menuRef}>
        <Button1 style={{ marginLeft: '30px' }} onClick={handleAtualizarQrCode}>
          Clique Aqui Para Atualizar QRCode
        </Button1>
        <FontAwesomeIcon
          onClick={() => toggleOpcoes()}
          style={{ cursor: 'pointer', marginLeft: '10px' }}
          icon={faEllipsisV}
        />
        {opcoesAbertas && (
          <ul
            style={{
              marginTop: '95px',
              marginLeft: '350px',
              padding: '10px',
              borderRadius: '4px',
              position: 'absolute',
              zIndex: '999',
              backgroundColor: '#fff',
              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            }}
          >
            <li
              style={{ cursor: 'pointer', marginBottom: '5px' }}
              onClick={() => {
                history.push(`/ConfiguracoesWhatsApp`);
              }}
            >
              Configuração
            </li>
            <li
              style={{ cursor: 'pointer' }}
              onClick={() => {
                history.push(`/FerramentasWhatsApp`);
              }}
            >
              Ferramentas
            </li>
          </ul>
        )}
      </div>
      {gerarQrCode.connected === true ? (
        ''
      ) : (
        <img
          style={{ marginTop: '2.5%' }}
          src={gerarQrCode.value}
          alt="QrCode"
        />
      )}

      <h3
        style={{
          color: '#0d214f',
          margin: '50px auto',
        }}
      >
        {gerarQrCode.connected === true
          ? 'WhatsApp Conectado'
          : 'WhatsApp Desconectado'}
      </h3>
    </div>
  );
};

export default ConfigureWhatsApp;
