import { Avatar } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from '../../styles/GlobalCss.module.css';
// import logotipo from "../../icons/logotipo2.png";
import { AuthContext } from '../../contexts/AuthContext';
import api from '../../services/api';
import ErrorComponent from '../../components/errorComponent/errorComponent';

export const Profile = () => {
  const {
    user,
    getMessageByErrorCodeAxios,
    // getMessageError,
    // signOutFunc,
    verifyUserLoged,
  } = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [condition, setCondition] = useState(0);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    async function callApi() {
      const data = await api
        .get(
          'https://www.vetnaweb.com.br/testeapi/Vw_documentos?idInterno=' +
            `${user && user.idAvatar}`,
        )
        .then((response) => {
          // setAgenda(response.data);

          setAvatar(response.data);
          // setAvatarGoogle(response.data);
        })
        .catch((error) => {
          setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
          // eslint-disable-next-line eqeqeq
          setCondition(condition == 0 ? 1 : 0);
        });
      return data;
    }
    verifyUserLoged().then((response) => {
      response && callApi();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.defaultGrid}>
      <div className={styles.teste1}>
        <Avatar
          src={
            user && user.idGoogle
              ? user.photoGoogle
              : `data:image/jpeg;base64,${avatar}`
          }
          sx={{ width: '100%', height: '' }}
        />
      </div>
      <ErrorComponent
        error={error}
        errorMessage={errorMessage}
        condition={condition}
      />
    </div>
  );
};
export default Profile;
