import React, { useState } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

const RequesterPage = (props) => {
  const history = useHistory();
  const location = useLocation();

  const savedData = JSON.parse(localStorage.getItem('dadosRequisitante'));

  const nomeSolicitante2 = savedData?.nomeSolicitante;
  const crmv2 = savedData?.crmv;
  const email2 = savedData?.email;

  const [nomeSolicitante, setNomeSolicitante] = useState(
    nomeSolicitante2 || '',
  );
  const [crmv, setCrmv] = useState(crmv2 || '');

  const [email, setEmail] = useState(email2 || '');

  const handleNome = (event) => {
    setNomeSolicitante(event.target.value);
  };
  const handleCrmv = (event) => {
    setCrmv(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  function handleNext() {
    const dateToSave = {
      nomeSolicitante,
      crmv,
      email,
    };

    const jsonData = JSON.stringify(dateToSave);

    localStorage.setItem('dadosRequisitante', jsonData);

    history.push({
      pathname: '/PedidoDeExame/Exame',
    });
  }

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Preencha a Solicitação de Exame
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>Solicitante</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nome:
            </p>
            <input
              type="text"
              onChange={handleNome}
              style={{ width: '300px' }}
              value={nomeSolicitante}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              CRMV:
            </p>
            <input
              type="text"
              onChange={handleCrmv}
              style={{ width: '300px' }}
              value={crmv}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              E-mail:
            </p>
            <input
              type="text"
              onChange={handleEmail}
              style={{ width: '300px' }}
              value={email}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleNext}
        >
          Próximo
        </Button1>
      </div>
    </div>
  );
};

export default RequesterPage;
