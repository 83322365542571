import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';
import { AuthContext } from '../../contexts/AuthContext';
import UpdateScreen from '../UpddateScreen/UpdateScreen';
import api from '../../services/api';

const AddQuotationItens = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [info, setInfo] = useState([]);

  const [descricao, setDescricao] = useState('');
  const [codBarras, setCodBarras] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [unidade, setUnidade] = useState('');
  const [observacao, setObservacao] = useState('');

  const handleDescricao = (event) => {
    setDescricao(event.target.value);
  };

  const handleCodBarras = (event) => {
    setCodBarras(event.target.value);
  };

  const handleQuantidade = (event) => {
    setQuantidade(event.target.value);
  };

  const handleUnidade = (event) => {
    setUnidade(event.target.value);
  };

  const handleObservacao = (event) => {
    setObservacao(event.target.value);
  };

  useEffect(() => {
    async function infoQuotation() {
      try {
        const response = await api.get(`/Cotacao/${id}`);
        setInfo(response.data);
      } catch (error) {}
    }
    infoQuotation();
  }, [id]);

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apicw/CotacaoItens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        idCotacao: id,
        idCliente: info.idCliente,
        descricao: descricao,
        codigoBarras: codBarras,
        quantidade: quantidade,
        unidade: unidade,
        observacao: observacao || '',
      }),
    }).then(() => {
      history.goBack();
    });
  }

  const [acessoItemCotacao, setAcessoItemCotacao] = useState();

  useEffect(() => {
    async function acessoItemCotacao() {
      try {
        const response =
          await api.get(`/AcessoCotacao?email=${user.email}&idCotacao=${id}
        `);
        setAcessoItemCotacao(response.data);
      } catch (error) {}
    }
    acessoItemCotacao();
  }, [user.email, id]);

  return (
    <div>
      {acessoItemCotacao <= 0 ? (
        <UpdateScreen />
      ) : (
        <div>
          <h2
            style={{
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '10px',
              color: '#0d214f',
            }}
          >
            Adicionar Item na Cotação
          </h2>
          <h4
            style={{
              textAlign: 'center',
              marginTop: '10px',
              marginBottom: '40px',
              color: '#0d214f',
            }}
          >
            {info.descricao}
          </h4>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#0d214f',
                }}
              >
                Descrição
              </p>
              <input
                style={{
                  marginLeft: '5px',
                  width: '200px',
                  borderRadius: '5px',
                  textAlign: 'left',
                  paddingLeft: '10px',
                }}
                type="text"
                value={descricao}
                onChange={handleDescricao}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#0d214f',
                }}
              >
                Código de Barras
              </p>
              <input
                style={{
                  marginLeft: '5px',
                  width: '200px',
                  borderRadius: '5px',
                  textAlign: 'left',
                  paddingLeft: '10px',
                }}
                type="text"
                value={codBarras}
                onChange={handleCodBarras}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#0d214f',
                }}
              >
                Quantidade
              </p>
              <input
                style={{
                  marginLeft: '5px',
                  width: '200px',
                  borderRadius: '5px',
                  textAlign: 'left',
                  paddingLeft: '10px',
                }}
                type="text"
                value={quantidade}
                onChange={handleQuantidade}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#0d214f',
                }}
              >
                Unidade
              </p>
              <input
                style={{
                  marginLeft: '5px',
                  width: '200px',
                  borderRadius: '5px',
                  textAlign: 'left',
                  paddingLeft: '10px',
                }}
                type="text"
                value={unidade}
                onChange={handleUnidade}
              />
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '20px',
              }}
            >
              <p
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#0d214f',
                }}
              >
                Observação
              </p>
              <textarea
                style={{
                  marginTop: '5px',
                  borderRadius: '5px',
                  paddingLeft: '10px',
                  paddingTop: '5px',
                  marginLeft: '10px',
                }}
                name="observacao"
                id="observacao"
                cols="30"
                rows="5"
                value={observacao}
                onChange={handleObservacao}
              ></textarea>
            </div>
            <div style={{ margin: '20px auto' }}>
              <Button1
                style={{
                  display: 'inline-block',
                  width: '47%',
                }}
                onClick={() => history.goBack()}
              >
                Cancelar
              </Button1>
              <Button1
                style={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  width: '47%',
                }}
                onClick={handleSave}
              >
                Salvar
              </Button1>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddQuotationItens;
