import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginForm from '../pages/Authentication/SignIn';
import LoginCreate from '../pages/Authentication/SignUp';
import LoginPasswordLost from '../pages/Authentication/RecoverPassword';
import NotFound from '../pages/NotFound';
import styles from '../styles/Login.module.css';
import Header from '../components/Header';
import AddBuyer from '../pages/AddBuyer/AddBuyer';

function AuthRoutes() {
  return (
    <section className={styles.login}>
      <Header />
      <div className={styles.forms}>
        <Switch>
          <Route exact path="/">
            <LoginForm />
          </Route>

          <Route exact path="/Cadastrar">
            <LoginCreate />
          </Route>

          <Route exact path="/RecuperarSenha">
            <LoginPasswordLost />
          </Route>

          <Route exact path="/Cadastro">
            <AddBuyer />
          </Route>

          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </section>
  );
}

export default AuthRoutes;
