import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const DeleteItemQuotation = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const idCliente = props.location.state.idCliente;

  const [infoItem, setInfoItem] = useState([]);

  useEffect(() => {
    async function infoItem() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/CotacaoItens/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setInfoItem(data[0]);
        });
    }
    infoItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDelete() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/CotacaoItensDelete?idParceiro=${idCliente}&idCotacao=${infoItem.idCotacao}&idItem=${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
      },
    ).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      {' '}
      <h2
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        Excluir Cotação
      </h2>
      <h3
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        Tem certeza que deseja excluir o item {infoItem.descricao}?
      </h3>
      <div
        style={{
          margin: '50px auto',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button1
          style={{
            display: 'inline-block',
            width: '10%',
          }}
          onClick={handleDelete}
        >
          Sim
        </Button1>
        <Button1
          style={{
            display: 'inline-block',
            marginLeft: '15px',
            width: '10%',
          }}
          onClick={() => history.goBack()}
        >
          Não
        </Button1>
      </div>
    </div>
  );
};

export default DeleteItemQuotation;
