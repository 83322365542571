import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const AddSupplier = (props) => {
  const history = useHistory();

  const idAcesso = props.location.state.idAcesso;

  const [empresa, setEmpresa] = useState('');
  const [representante, setRepresentante] = useState('');
  const [email, setEmail] = useState('');
  const [whatsApp, setWhatsApp] = useState('');
  const [grupo, setGrupo] = useState('');
  const [ativo, setAtivo] = useState(1);

  const handleEmpresa = (event) => {
    setEmpresa(event.target.value);
  };

  const handleRepresentante = (event) => {
    setRepresentante(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleWhatsApp = (event) => {
    setWhatsApp(event.target.value);
  };

  const handleGrupo = (event) => {
    setGrupo(event.target.value);
  };

  const handleAtivo = (event) => {
    setAtivo(event.target.value);
  };

  function handleSave() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/Fornecedor?idParceiro=${idAcesso}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          id: 0,
          idCliente: idAcesso,
          empresa: empresa,
          representante: representante,
          email: email,
          whatsapp: whatsApp,
          grupo: grupo,
          ativo: ativo,
        }),
      },
    ).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Adicionar Fornecedor
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: '5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Empresa:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={empresa}
              onChange={handleEmpresa}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Representante:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={representante}
              onChange={handleRepresentante}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              E-mail:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={email}
              onChange={handleEmail}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              WhatsApp:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={whatsApp}
              onChange={handleWhatsApp}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Grupo:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={grupo}
              onChange={handleGrupo}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Ativo:{' '}
            </p>
            <select
              style={{
                marginLeft: '5px',
                width: '400px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              value={ativo}
              onChange={handleAtivo}
            >
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>
          </div>
          <div style={{ margin: '30px auto' }}>
            <Button1
              style={{
                display: 'inline-block',
                width: '47%',
              }}
              onClick={handleSave}
            >
              Salvar
            </Button1>
            <Button1
              style={{
                display: 'inline-block',
                marginLeft: '10px',
                width: '47%',
              }}
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button1>{' '}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSupplier;
