import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';

const Result = (props) => {
  const history = useHistory();

  const { id } = useParams();

  const [result, setResult] = useState([]);
  const [info, setInfo] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState({});

  const toggleOpcoes = (index) => {
    const novasOpcoesAbertas = {};

    Object.keys(opcoesAbertas).forEach((key) => {
      novasOpcoesAbertas[key] = false;
    });

    novasOpcoesAbertas[index] = !opcoesAbertas[index];

    setOpcoesAbertas(novasOpcoesAbertas);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tabelaElement = document.getElementById('tableQuotation');
      if (tabelaElement && !tabelaElement.contains(event.target)) {
        setOpcoesAbertas({});
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function info2() {
      try {
        const response = await api.get(`/Cotacao/${id}`);
        setInfo(response.data);
      } catch (error) {}
    }
    info2();
  }, [id]);

  useEffect(() => {
    async function resultQuotation2() {
      try {
        const response = await api.get(`/ResultadoCotacao?idCotacao=${id}`);
        setResult(response.data);
      } catch (error) {}
    }
    resultQuotation2();
  }, [id]);

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [relatoriosAbertos, setRelatoriosAbertos] = useState(false);

  const toggleRelatorios = () => {
    setRelatoriosAbertos(!relatoriosAbertos);
  };

  return (
    <div>
      <h2
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        Resultado Cotação
      </h2>
      <h4
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '40px',
        }}
      >
        {info.descricao} - Válida até{' '}
        {info.data
          ?.split('T')[0]
          .split('-')
          .join('/')
          .replace(/^(\d{4})\/(\d{2})\/(\d{2}).*$/, '$3/$2/$1')}{' '}
        às {info.horario?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2')}
        {'h'}
      </h4>
      <Button1 style={{ marginLeft: '80%' }} onClick={toggleRelatorios}>
        Relatórios
      </Button1>
      {relatoriosAbertos && (
        <ul
          style={{
            marginTop: '-12px',
            marginLeft: '77.5%',
            padding: '10px',
            borderRadius: '4px',
            position: 'absolute',
            zIndex: '999',
            backgroundColor: '#fff',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
          }}
        >
          <li
            style={{ cursor: 'pointer', marginBottom: '10px' }}
            onClick={() => {
              history.push(`/Relatorio/ListagemDeResultado/${id}`, {
                idCotacao: id,
              });
            }}
          >
            Listagem de Resultado
          </li>
          <li
            style={{
              cursor: 'pointer',
              marginBottom: '10px',
            }}
            onClick={() => {
              history.push(`/Relatorio/ListagemComTodosOsPrecos/${id}`, {
                idCotacao: id,
              });
            }}
          >
            Listagem com Todos os Preços
          </li>
          <li
            style={{ cursor: 'pointer' }}
            onClick={() => {
              history.push(`/Relatorio/PedidosPorFornecedor/${id}`, {
                idCotacao: id,
              });
            }}
          >
            Pedidos por Fornecedor
          </li>
        </ul>
      )}
      <table className="estilizada2" id="tableQuotation">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Quantidade</th>
            <th>Unidade</th>
            <th>Fornecedor</th>
            <th>Preço</th>
            <th>Observação do Fornecedor</th>
          </tr>
        </thead>
        <tbody>
          {result.map((tabela, index) => (
            <tr
              key={index}
              style={{
                backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
              }}
              onMouseEnter={() => handleRowEnter(index)}
              onMouseLeave={handleRowLeave}
            >
              <td onClick={() => toggleOpcoes(index)}>{tabela.descricao}</td>
              <td onClick={() => toggleOpcoes(index)}>{tabela.quantidade}</td>
              <td onClick={() => toggleOpcoes(index)}>{tabela.unidade}</td>
              <td onClick={() => toggleOpcoes(index)}>{tabela.fornecedor}</td>

              <td
                style={{ textAlign: 'right' }}
                onClick={() => toggleOpcoes(index)}
              >
                {tabela.Preco.toFixed(2)}
              </td>
              <td onClick={() => toggleOpcoes(index)}>{tabela.observacao}</td>
              <td style={{ textAlign: 'center' }}>
                {' '}
                <FontAwesomeIcon
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                  icon={faEllipsisV}
                />
              </td>
              {opcoesAbertas[index] && (
                <ul
                  style={{
                    marginTop: '37px',
                    marginLeft: '-105px',
                    padding: '10px',
                    borderRadius: '4px',
                    position: 'absolute',
                    zIndex: '999',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <li
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      history.push(`/Cotacoes/${tabela.id}`, {
                        idCliente: tabela.idCliente,
                      });
                    }}
                  >
                    Ver Cotações
                  </li>
                </ul>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Result;
