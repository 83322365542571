import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

const WhatsAppTools = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [carregado2, setCarregado2] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idAcesso = clinicalList.idAcesso;

  const [infoConfig, setInfoConfig] = useState([]);

  useEffect(() => {
    if (carregado) {
      async function config() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/ConfigWhatsApp?idParceiro=${idAcesso}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setInfoConfig(data);
            setCarregado2(true);
          });
      }
      config();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const [status, setStatus] = useState();
  const [statusCelular, setStatusCelular] = useState();
  const [desconectar, setDesconectar] = useState();

  function handleStatus() {
    fetch(
      `https://api.plugzapi.com.br/instances/${infoConfig.instancia}/token/${infoConfig.token}/status`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStatus(data);
        setValue(value);
      });
  }

  function handleStatusCelular() {
    fetch(
      `https://api.plugzapi.com.br/instances/${infoConfig.instancia}/token/${infoConfig.token}/device`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setStatus();
        setStatusCelular(data);
      });
  }

  function handleDesconectar() {
    setStatus('Desconectando o WhatsApp... Aguarde.');
    fetch(
      `https://api.plugzapi.com.br/instances/${infoConfig.instancia}/token/${infoConfig.token}/disconnect
      `,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
      },
    )
      .then((response) => response.json())
      .then((data) => {
        setDesconectar(data);
        setStatus('WhatsApp Desconectado!');
      });
  }

  const [value, setValue] = useState('');

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Ferramentas WhatsApp
      </h2>
      <div style={{ display: 'flex' }}>
        <div style={{ marginLeft: '20%' }}>
          <Button1 style={{ width: '200px' }} onClick={handleStatus}>
            Status
          </Button1>
          <Button1 style={{ width: '200px' }} onClick={handleStatusCelular}>
            Status Celular
          </Button1>
          <Button1 style={{ width: '200px' }} onClick={handleDesconectar}>
            Desconectar
          </Button1>
        </div>

        <textarea
          cols="55"
          rows="10"
          style={{ marginLeft: '5%', marginTop: '1%' }}
          value={
            JSON.stringify(status) ||
            JSON.stringify(statusCelular) ||
            JSON.stringify(desconectar)
          }
        ></textarea>
      </div>
    </div>
  );
};

export default WhatsAppTools;
