import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
// import Spinner from 'react-bootstrap/Spinner';
import Lottie from 'react-lottie';
import dog from '../animation/animationLoadSmall.json';
// import dog2 from '../animation/animationLoad.json';
import styles from '../styles/Login.module.css';
import style from '../styles/GlobalCss.module.css';
// import styles2 from '../components/Lists/Lists.module.css';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';
import Header from '../components/Header';
import HeaderUser from '../components/HeaderUser';

function Routes() {
  const { signed, loading, conditionLoading } = useContext(AuthContext);

  // const [defaultOptions, setDefaultOptions] = useState();

  const defaultOptions = {
    width: 5,
    height: 5,
    loop: true,
    autoplay: true,
    animationData: dog,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // eslint-disable-next-line eqeqeq
  if (loading && window.location.pathname == '/') {
    return conditionLoading ? (
      <div
        className={style.defaultGrid}
        style={{ overflow: 'hidden', gridTemplateRows: '1fr', bottom: '0' }}
      >
        <Header />
        <section
          className={styles.login}
          style={{ gridColumn: '1/9', height: '100%' }}
        >
          <section
            className="animeLeft"
            style={{
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '100%',
                placeContent: 'center',
              }}
            >
              <Lottie
                style={{
                  cursor: 'auto',

                  width: 'max-content',
                }}
                options={defaultOptions}
                isClickToPauseDisabled={true}
              />
            </div>
          </section>
        </section>
      </div>
    ) : (
      <div
        className={style.defaultGrid}
        style={{ overflow: 'hidden', gridTemplateRows: '1fr', bottom: '0' }}
      >
        <HeaderUser />
        <section style={{ gridColumn: '1/9', height: '100%' }}>
          <section
            className="animeLeft"
            style={{
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '100%',
                placeContent: 'center',
              }}
            >
              <Lottie
                style={{
                  cursor: 'auto',

                  width: 'max-content',
                }}
                options={defaultOptions}
                isClickToPauseDisabled={true}
              />
            </div>
          </section>
        </section>
      </div>
    );

    // return <Spinner animation="border" variant="success" />;
  } else if (loading) {
    return (
      <div
        className={style.defaultGrid}
        style={{ overflow: 'hidden', gridTemplateRows: '1fr', bottom: '0' }}
      >
        <HeaderUser />
        <section style={{ gridColumn: '1/9', height: '100%' }}>
          <section
            className="animeLeft"
            style={{
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                height: '100%',

                placeContent: 'center',
              }}
            >
              <Lottie
                style={{
                  cursor: 'auto',

                  width: 'max-content',
                }}
                options={defaultOptions}
                isClickToPauseDisabled={true}
              />
            </div>
          </section>
        </section>
      </div>
    );
  }

  return signed ? <AppRoutes /> : <AuthRoutes />;
}

export default Routes;
