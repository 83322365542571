import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

const WhatsAppSettings = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idAcesso = clinicalList.idAcesso;

  const [infoConfig, setInfoConfig] = useState([]);

  useEffect(() => {
    if (carregado) {
      async function config() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/ConfigWhatsApp?idParceiro=${idAcesso}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setInfoConfig(data);
            setApiInstancia(data?.api);
            setInstancia(data?.instancia);
            setToken(data?.token);
          });
      }
      config();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const [apiInstancia, setApiInstancia] = useState('');
  const [instancia, setInstancia] = useState('');
  const [token, setToken] = useState('');

  const handleApiInstancia = (event) => {
    setApiInstancia(event.target.value);
  };

  const handleInstancia = (event) => {
    setInstancia(event.target.value);
  };

  const handleToken = (event) => {
    setToken(event.target.value);
  };

  function handleSave() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/ConfigWhatsApp?idParceiro=${idAcesso}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          id: infoConfig?.id ? infoConfig?.id : 0,
          idCliente: idAcesso,
          api: apiInstancia,
          instancia: instancia,
          token: token,
        }),
      },
    ).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Configurações WhatsApp
      </h2>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: '5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              API da Instância:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '350px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={apiInstancia}
              onChange={handleApiInstancia}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              ID da Instância:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '350px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={instancia}
              onChange={handleInstancia}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Token da Instância:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '350px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={token}
              onChange={handleToken}
            />
          </div>
        </div>
        <div style={{ margin: '30px auto' }}>
          <Button1
            style={{
              display: 'inline-block',
              width: '47%',
            }}
            onClick={handleSave}
          >
            Salvar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>{' '}
        </div>
      </div>
    </div>
  );
};

export default WhatsAppSettings;
