import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

const ErrorEmailPage = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3
        style={{
          textAlign: 'center',
          marginTop: '100px',
          marginBottom: '40px',
          color: '#0d214f',
          width: '80%',
          margin: '40px auto',
        }}
      >
        Erro ao enviar o Email. Favor verificar os dados novamente.
      </h3>
      <Button1
        style={{
          textAlign: 'center',
          marginTop: '40px',
          marginBottom: '40px',
          color: '#0d214f',
          width: '20%',
          margin: '40px auto',
        }}
        onClick={history.goBack()}
      >
        OK
      </Button1>
    </div>
  );
};

export default ErrorEmailPage;
