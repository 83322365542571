import React from 'react';

// eslint-disable-next-line no-unused-vars
const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Preencha um email válido',
  },
};

const useForm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { type, newValue } = props;
  const [value, setValue] = React.useState(newValue != null ? newValue : '');
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState(null);

  // function validate(value) {
  //   if (type === false) return true;
  //   if (value.toString().length === 0) {
  //     setError('Preencha um valor.');
  //     return false;
  //   } else if (types[type] && !types[type].regex.test(value)) {
  //     setError(types[type].message);
  //     return false;
  //   } else {
  //     setError(null);
  //     return true;
  //   }
  // }

  function onChange({ target }) {
    // if (error) validate(target.value);
    setValue(target.value);
  }

  return {
    value,
    setValue,
    onChange,
    error,
    // validate: () => validate(value),
    // onBlur: () => validate(value),
  };
};

export default useForm;
