import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Button1 } from '../../components/Forms/FormItems';

const UpdateScreen = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        color: '#0d214f',
        marginBottom: '20px',
      }}
    >
      <h1>Atualização Necessária</h1>
      {/* <h2></h2> */}
    </div>
  );
};

export default UpdateScreen;
