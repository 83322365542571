import React from 'react';
import styles from '../../styles/GlobalCss.module.css';

export const Button1 = ({
  children,
  personalizado,
  className,

  ...props
}) => {
  return (
    <button
      {...props}
      className={
        className !== undefined ? className : className + ' ' + styles.button1
      }
    >
      {children}
    </button>
  );
};
export const Button2 = ({ children, personalizado, ...props }) => {
  return <button {...props}>{children}</button>;
};
export const Button3 = ({ children, personalizado, ...props }) => {
  return (
    <button {...props} className={styles.button3}>
      {children}
    </button>
  );
};
export const Button4 = ({
  children,
  personalizado,
  className,

  ...props
}) => {
  return (
    <button
      {...props}
      className={
        className !== undefined ? className : className + ' ' + styles.button4
      }
    >
      {children}
    </button>
  );
};
export const ButtonBack = ({ children, personalizado, ...props }) => {
  return (
    <button {...props} className={styles.buttonBack}>
      {children}
    </button>
  );
};
