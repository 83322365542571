import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';

const CompanyParameters = (props) => {
  const history = useHistory();

  const [cliente, setCliente] = useState('');

  useEffect(() => {
    async function supplierData() {
      try {
        const response = await api.get(`/Clientes`);
        setCliente(response.data);
      } catch (error) {}
    }
    supplierData();
  }, []);

  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState('');
  const [crmv, setCrmv] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [cidade, setCidade] = useState('');
  const [cep, setCep] = useState('');
  const [telefone, setTelefone] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [instagram, setInstagram] = useState('');
  const [email, setEmail] = useState('');
  const [idCliente, setIdCliente] = useState('');

  const [infoCliente, setInfoCliente] = useState([]);

  useEffect(() => {
    async function parametersData() {
      try {
        const response = await api.get(`/Clientes/${1}`);
        setInfoCliente(response.data);
        setNomeFantasia(response.data.nomeEmpresa);
        setCnpj(response.data.cnpj);
        setEndereco(response.data.endereco);
        setNumero(response.data.numero);
        setComplemento(response.data.complemento);
        setBairro(response.data.bairro);
        setEstado(response.data.estado);
        setCidade(response.data.cidade);
        setCep(response.data.cep);
        setTelefone(response.data.telefone1);
        setWhatsapp(response.data.telefone2);
        setEmail(response.data.emailPrincipal);
        setIdCliente(response.data.id);
      } catch (error) {}
    }
    parametersData();
  }, []);

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apiveus/Clientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        id: idCliente,
        nomeEmpresa: nomeFantasia,
        emailPrincipal: email,
        telefone1: telefone,
        telefone2: whatsapp,
        bloqueadoem: null,
        idlicenca: null,
        cnpj: cnpj,
        endereco: endereco,
        numero: numero,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        uf: estado,
        cep: cep,
        liberaparcial: null,
      }),
    }).then(() => {
      alert('Salvo com sucesso!');
      history.goBack();
    });
  }

  function handleRazaoSocial(event) {
    setRazaoSocial(event.target.value);
  }

  function handleNomeFantasia(event) {
    setNomeFantasia(event.target.value);
  }

  function handleCnpj(event) {
    setCnpj(event.target.value);
  }

  function handleInscricaoEstadual(event) {
    setInscricaoEstadual(event.target.value);
  }

  function handleInscricaoMunicipal(event) {
    setInscricaoMunicipal(event.target.value);
  }

  function handleCrmv(event) {
    setCrmv(event.target.value);
  }

  function handleCrmv(event) {
    setCrmv(event.target.value);
  }

  function handleEndereco(event) {
    setEndereco(event.target.value);
  }

  function handleNumero(event) {
    setNumero(event.target.value);
  }

  function handleComplemento(event) {
    setComplemento(event.target.value);
  }

  function handleBairro(event) {
    setBairro(event.target.value);
  }

  function handleEstado(event) {
    setEstado(event.target.value);
  }

  function handleCidade(event) {
    setCidade(event.target.value);
  }

  function handleCep(event) {
    setCep(event.target.value);
  }

  function handleTelefone(event) {
    setTelefone(event.target.value);
  }

  function handleWhatsapp(event) {
    setWhatsapp(event.target.value);
  }

  function handleInstagram(event) {
    setInstagram(event.target.value);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  return (
    <div style={{ marginBottom: '20%' }}>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Parâmetros da Empresa
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nome Empresa:
            </p>
            <input
              type="text"
              value={nomeFantasia}
              onChange={handleNomeFantasia}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              CNPJ:
            </p>
            <input type="text" value={cnpj} onChange={handleCnpj} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Endereço:
            </p>
            <input type="text" value={endereco} onChange={handleEndereco} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Número:
            </p>
            <input type="text" value={numero} onChange={handleNumero} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Complemento:
            </p>
            <input
              type="text"
              value={complemento}
              onChange={handleComplemento}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Bairro:
            </p>
            <input type="text" value={bairro} onChange={handleBairro} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Estado:
            </p>
            <input type="text" value={estado} onChange={handleEstado} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Cidade:
            </p>
            <input type="text" value={cidade} onChange={handleCidade} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Cep:
            </p>
            <input type="text" value={cep} onChange={handleCep} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Telefone:
            </p>
            <input type="text" value={telefone} onChange={handleTelefone} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              WhatsApp:
            </p>
            <input type="text" value={whatsapp} onChange={handleWhatsapp} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              E-mail:
            </p>
            <input type="text" value={email} onChange={handleEmail} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleSave}
        >
          Salvar
        </Button1>
      </div>
      <div style={{ height: '80px' }}></div>
    </div>
  );
};

export default CompanyParameters;
