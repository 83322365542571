/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import styles from '../../styles/HeaderUser.module.css';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import Avatar from '@material-ui/core/Avatar';
import Quagga from 'quagga';

import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';

import api from '../../services/api';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import HomeIcon from '@mui/icons-material/Home';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ErrorComponent from '../errorComponent/errorComponent';
import { ArrowRight } from '@material-ui/icons';

window.addEventListener('beforeunload', function (event) {
  Quagga.stop();
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#0073cf',

  height: '62.5px !important',
  transition: 'height 2s',
  '@media screen and (max-width: 600px)': {
    height: '53px !important',
  },
  padding: theme.spacing(0, 2),
  // necessary for content to be below app bar
}));

export const HeaderUser = (props) => {
  const {
    user,
    getMessageByErrorCodeAxios,
    getMessageError,
    signOutFunc,
    loading,
    verifyUserLoged,
  } = React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);
  const [condition, setCondition] = useState(0);
  const [avatar, setAvatar] = React.useState(null);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [clinicalList, setClinicalList] = React.useState([{}]);

  const email = window.localStorage.getItem('email');

  React.useEffect(() => {
    verifyUserLoged().then((responseLoged) => {
      responseLoged &&
        api
          .get('https://www.vetnaweb.com.br/apiveus/Validacao?email=' + `email`)
          .then((response) => {
            setClinicalList(response.data);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = () => {
    window.open(
      'http://www.cotanaweb.com.br/TermosDeUso/TermodeUsoUsuarioFinalAPPCotaNaWeb.pdf',
      '_blank',
    );
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: `350px`,
        boxSizing: 'border-box',
        transition: 'width 2s',

        '@media screen and (max-width: 1200px)': {
          width: `250px`,
          boxSizing: 'border-box',
        },

        flexShrink: 0,
        '@media screen and (max-width: 500px)': {
          width: `350px`,
          boxSizing: 'border-box',
        },
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <p
          style={{
            fontSize: '120%',
            color: '#0d214f',
            fontWeight: 'bold',
            fontFamily: 'Nunito',
            margin: '0 auto',
          }}
        >
          {loading ? <CircularProgress color="primary" /> : user.nome}
        </p>
      </DrawerHeader>
      {clinicalList.TipoAcesso === 'C' ? (
        <List sx={{ marginTop: '8%' }}>
          <ListItem
            onClick={() => {
              history.push('/');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Exames Laboratoriais
            </p>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push('/ParametrosDaEmpresa');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Parâmetros da Empresa
            </p>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push('/ParametrosDeIntegracao');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Parâmetros de Integração
            </p>
          </ListItem>
          <ListItem
            onClick={() =>
              history.push({
                pathname: '/Cadastro/Atendente',
                state: { idAcesso: clinicalList.idAcesso },
              })
            }
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Cadastro Atendentes
            </p>
          </ListItem>
          <ListItem onClick={handleRedirect}>
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Termo de Uso
            </p>
          </ListItem>
          <ListItem onClick={handleRedirect}>
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
                cursor: 'pointer',
              }}
            >
              Política de Privacidade
            </p>
          </ListItem>
          <ListItem onClick={() => logout()}>
            <ListItemIcon sx={{ marginLeft: '1%' }}>
              <LogoutIcon fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-6%',
              }}
            >
              Sair
            </p>
          </ListItem>
        </List>
      ) : clinicalList.TipoAcesso === 'C' ? (
        <List sx={{ marginTop: '8%' }}>
          <ListItem
            onClick={() => {
              history.push('/');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Exames Laboratoriais
            </p>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push('/ParametrosDaEmpresa');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Parâmetros da Empresa
            </p>
          </ListItem>
          <ListItem
            onClick={() => {
              history.push('/ParametrosDeIntegracao');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Parâmetros de Integração
            </p>
          </ListItem>
          <ListItem onClick={handleRedirect}>
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Termo de Uso
            </p>
          </ListItem>
          <ListItem onClick={handleRedirect}>
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Política de Privacidade
            </p>
          </ListItem>
          <ListItem onClick={() => logout()}>
            <ListItemIcon sx={{ marginLeft: '1%' }}>
              <LogoutIcon fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-6%',
              }}
            >
              Sair
            </p>
          </ListItem>
        </List>
      ) : (
        <List sx={{ marginTop: '8%' }}>
          <ListItem
            onClick={() => {
              history.push('/');
            }}
          >
            <ListItemIcon>
              <ArrowRight fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-7%',
              }}
            >
              Exames Laboratoriais
            </p>
          </ListItem>
          <ListItem onClick={() => logout()}>
            <ListItemIcon sx={{ marginLeft: '1%' }}>
              <LogoutIcon fontSize="medium" color="primary" />
            </ListItemIcon>
            <p
              style={{
                fontSize: '1.3rem',
                fontWeight: 'bold',
                color: '#084d6e',
                marginLeft: '-6%',
              }}
            >
              Sair
            </p>
          </ListItem>
        </List>
      )}
    </Box>
  );

  const openP = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function logout() {
    localStorage.removeItem('email');
    localStorage.removeItem('newDataSelecionada');
    signOutFunc();
    setAnchorEl(null);
  }

  function handleBack(event) {
    event.preventDefault();
    history.goBack();
  }
  function abridgedControl(fullName, limit) {
    if (fullName.length > limit) {
      return toAbridged(fullName);
    }
    return fullName;
  }

  function toAbridged(fullName) {
    const token = '.';
    const separator = ' ';
    const names = removePrepositions(fullName).split(separator);
    const firstName = names[0];
    const lastName = names[names.length - 1];
    let surnames = '';
    names
      .filter((name, index) => index)
      .map(
        (name) =>
          (surnames +=
            // eslint-disable-next-line eqeqeq
            name != lastName
              ? `${separator}${name.charAt()}${token}`
              : `${separator}${lastName}`),
      );
    return `${firstName}${surnames.toUpperCase()}`;
  }

  function removePrepositions(fullName) {
    return fullName.replace(/\ dos|\ das|\ dos|\ das|\ de|\ d\'/gi, '');
  }

  return (
    <header className={styles.header}>
      <nav className={styles.nav}>
        <div>
          {
            <React.Fragment key={'left'}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer('left', true)}
                edge="start"
                sx={{ ...open }}
              >
                <MenuIcon
                  color="primary"
                  sx={{
                    fontSize: '2.2rem',
                    transition: ' 2s ',
                    '@media screen and (max-width: 600px)': {
                      fontSize: '1.6rem',
                    },
                  }}
                />
              </IconButton>

              <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
              >
                {list('left')}
              </SwipeableDrawer>
            </React.Fragment>
          }
        </div>

        {!(
          // eslint-disable-next-line eqeqeq
          // === por conta do eslint, qualquer erro retornar para ==
          (
            window.location.href === 'http://localhost:3000/' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'http://veus.vetnaweb.com.br/' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'veus.vetnaweb.com.br'
          )
        ) && (
          <IconButton color="primary" edge="start" onClick={handleBack}>
            <ArrowBackIcon
              sx={{
                fontSize: '1.6rem',
                fontWeight: '400',
              }}
            />
          </IconButton>
        )}
        {!(
          // eslint-disable-next-line eqeqeq
          // === por conta do eslint, qualquer erro retornar para ==
          (
            window.location.href === 'http://localhost:3000/' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'http://cota.vetnaweb.com.br/' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'https://cota.vetnaweb.com.br/' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'http://www.cotanaweb.com.br' ||
            // eslint-disable-next-line eqeqeq
            window.location.href == 'https://www.cotanaweb.com.br'
          )
        ) && (
          <NavLink exact to="/" activeStyle={{ color: 'white' }}>
            <IconButton color="primary" edge="start">
              <HomeIcon
                color="primary"
                sx={{
                  fontSize: '2.2rem',
                  transition: ' 2s ',
                  '@media screen and (max-width: 1200px)': {
                    fontSize: '1.6rem',
                  },
                }}
              />
            </IconButton>
          </NavLink>
        )}
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={openP ? 'true' : undefined}
            onClick={(event) => {
              handleClick(event);
            }}
          >
            <Avatar
              src={
                user && user.idAvatarGoogle
                  ? user.idAvatarGoogle
                  : `data:image/jpeg;base64,${avatar}`
              }
              variant="circular"
              sx={{
                width: '50px',
                height: '50px',
                marginRight: '10px',
                transition: ' 2s ',
                '@media screen and (max-width: 1200px)': {
                  width: '40px',
                  height: '40px',
                },
              }}
              alt={`${user.nome}`}
            />

            <p className="user-name">{abridgedControl(user.email, 10)}</p>
          </Button>
        )}
      </nav>
      <ErrorComponent
        error={error}
        errorMessage={errorMessage}
        condition={condition}
      />
    </header>
  );
};
export default HeaderUser;
