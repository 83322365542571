import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';
import api from '../../services/api';

const SendEmail = (props) => {
  const { id } = useParams();

  const idCotacao = id;

  const history = useHistory();

  const idCliente = props.location.state.idCliente;

  const [fornecedor, setFornecedor] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [todosChecked, setTodosChecked] = useState(false);
  const [grupoChecked, setGrupoChecked] = useState(false);
  const [fornecedorChecked, setFornecedorChecked] = useState(false);

  const handleTodosChange = () => {
    setTodosChecked(true);
    setGrupoChecked(false);
    setFornecedorChecked(false);
  };

  const handleGrupoChange = () => {
    setTodosChecked(false);
    setGrupoChecked(true);
    setFornecedorChecked(false);
  };

  const handleFornecedorChange = (event) => {
    setTodosChecked(false);
    setGrupoChecked(false);
    setFornecedorChecked(true);
  };

  const [listagemFornecedores, setListagemFornecedores] = useState([]);

  useEffect(() => {
    async function listagemFornecedores() {
      try {
        const response = await api.get(`/fornecedor?idParceiro=${idCliente}`);
        setListagemFornecedores(response.data);
      } catch (error) {}
    }
    listagemFornecedores();
  }, [idCliente]);

  const [idFornecedor, setIdFornecedor] = useState(0);
  const [grupoTexto, setGrupoTexto] = useState('');

  const handleGrupoTexto = (event) => {
    setGrupoTexto(event.target.value);
  };

  function handleSave() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/CotacaoEmail?idParceiro=${idCliente}&idCotacao=${id}&idFornecedor=${idFornecedor}&grupoFornecedor=${grupoTexto}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({}),
      },
    )
      .then(() => {
        alert('E-mail Enviado!');
      })
      .then(() => history.goBack());
  }

  return (
    <div>
      {' '}
      <h1
        style={{
          marginTop: '10px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
        }}
      >
        Enviar E-mail
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div>
          <div style={{ marginTop: '25px' }}>
            <input
              id="todosCheckbox"
              type="checkbox"
              style={{ display: 'inline-block' }}
              checked={todosChecked}
              onChange={handleTodosChange}
            />{' '}
            <label
              htmlFor="todosCheckbox"
              style={{
                display: 'inline-block',
                marginLeft: '5px',
                fontSize: '18px',
              }}
            >
              Todos
            </label>
          </div>
          <div>
            <input
              id="grupoCheckbox"
              type="checkbox"
              style={{ display: 'inline-block', marginTop: '20px' }}
              checked={grupoChecked}
              onChange={handleGrupoChange}
            />{' '}
            <label
              htmlFor="grupoCheckbox"
              style={{
                display: 'inline-block',
                marginLeft: '5px',
                fontSize: '18px',
              }}
            >
              Por Grupo
            </label>
            <input
              type="text"
              style={{
                display: 'inline-block',
                marginLeft: '10px',
                borderRadius: '5px',
              }}
              value={grupoTexto}
              onChange={handleGrupoTexto}
            />{' '}
          </div>
          <div>
            <input
              id="fornecedorCheckbox"
              type="checkbox"
              style={{ display: 'inline-block', marginTop: '20px' }}
              checked={fornecedorChecked}
              onChange={handleFornecedorChange}
            />{' '}
            <label
              htmlFor="fornecedorCheckbox"
              style={{
                display: 'inline-block',
                marginLeft: '5px',
                fontSize: '18px',
              }}
            >
              Por Fornecedor
            </label>
            <select
              name=""
              id=""
              style={{ marginLeft: '10px', borderRadius: '5px' }}
              onChange={handleFornecedorChange}
            >
              <option value="" selected>
                Selecione o Fornecedor...
              </option>
              {listagemFornecedores.map((lista) => (
                <option key={lista.id} value={lista.id}>
                  {lista.empresa} &nbsp; | &nbsp; {lista.representante}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ margin: '30px auto' }}>
          <Button1
            style={{
              display: 'inline-block',
              width: '47%',
            }}
            onClick={handleSave}
          >
            Enviar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>
        </div>
      </div>
    </div>
  );
};

export default SendEmail;
