import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';

const PetsPage = (props) => {
  const history = useHistory();

  const location = useLocation();

  const savedData = JSON.parse(localStorage.getItem('dadosPet'));

  const especie2 = savedData?.especie;
  const genero2 = savedData?.genero;
  const nascimentoPet2 = savedData?.nascimentoPet;
  const nomePet2 = savedData?.nomePet;
  const pelagem2 = savedData?.pelagem;
  const peso2 = savedData?.peso;
  const porte2 = savedData?.porte;
  const raca2 = savedData?.raca;
  const unidade2 = savedData?.unidade;

  const [nomePet, setNomePet] = useState(nomePet2 || '');
  const [nascimentoPet, setNascimentoPet] = useState(nascimentoPet2 || '');
  const [genero, setGenero] = useState(genero2 || 'Indefinido');
  const [especie, setEspecie] = useState(especie2 || '');
  const [raca, setRaca] = useState(raca2 || '');
  const [pelagem, setPelagem] = useState(pelagem2 || '21');
  const [porte, setPorte] = useState(porte2 || '');
  const [peso, setPeso] = useState(peso2 || '');
  const [unidade, setUnidade] = useState(unidade2 || 'KG');

  const handleNome = (event) => {
    setNomePet(event.target.value);
  };

  const handleNascimento = (event) => {
    setNascimentoPet(event.target.value);
  };

  const handleGenero = (event) => {
    setGenero(event.target.value);
  };

  const handleEspecie = (event) => {
    setEspecie(event.target.value);
  };

  const handleRaca = (event) => {
    setRaca(event.target.value);
  };

  const handlePelagem = (event) => {
    setPelagem(event.target.value);
  };

  const handlePorte = (event) => {
    setPorte(event.target.value);
  };

  const handlePeso = (event) => {
    setPeso(event.target.value);
  };

  const handleUnidade = (event) => {
    setUnidade(event.target.value);
  };

  const [species, setSpecies] = useState([]);

  useEffect(() => {
    async function speciesData() {
      try {
        const response = await api.get(`/Especies`);
        setSpecies(response.data);
      } catch (error) {}
    }
    speciesData();
  }, []);

  const [coats, setCoats] = useState([]);

  useEffect(() => {
    async function coatsData() {
      try {
        const response = await api.get(`/Pelagens`);
        setCoats(response.data);
      } catch (error) {}
    }
    coatsData();
  }, []);

  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    async function sizesData() {
      try {
        const response = await api.get(`/Portes`);
        setSizes(response.data);
      } catch (error) {}
    }
    sizesData();
  }, []);

  const [breeds, setBreeds] = useState([]);

  useEffect(() => {
    async function sizesData() {
      try {
        const response = await api.get(`/Racas?idEspecie=${especie}`);
        setBreeds(response.data);
      } catch (error) {}
    }
    sizesData();
  }, [especie]);

  function handleNext() {
    const dateToSave = {
      nomePet,
      nascimentoPet,
      genero,
      especie,
      raca,
      pelagem,
      porte,
      peso,
      unidade,
    };

    const jsonData = JSON.stringify(dateToSave);

    localStorage.setItem('dadosPet', jsonData);

    history.push({
      pathname: '/PedidoDeExame/Solicitante',
    });
  }

  return (
    <div style={{ marginBottom: '20%', textAlign: 'right' }}>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Preencha a Solicitação de Exame
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>Animal</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nome:
            </p>
            <input
              type="text"
              value={nomePet}
              onChange={handleNome}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Nascimento:
            </p>

            <input
              type="date"
              value={nascimentoPet}
              onChange={handleNascimento}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Gênero:
            </p>
            <select
              name=""
              id=""
              style={{ width: '300px' }}
              onChange={handleGenero}
              value={genero}
            >
              <option value="">Selecione a gênero...</option>
              <option value="Indefinido">Indefinido</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Espécie:
            </p>
            <select
              name=""
              id=""
              style={{ width: '300px' }}
              onChange={handleEspecie}
              value={especie}
            >
              <option value="">Selecione a espécie...</option>
              {species.map((specie) => (
                <option value={specie.id_veus}>{specie.nome}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Raça:
            </p>
            <select
              name=""
              id=""
              style={{ width: '300px' }}
              onChange={handleRaca}
              value={raca}
            >
              <option value="">Selecione a raça...</option>
              {breeds.map((breed) => (
                <option value={breed.id_veus}>{breed.nome}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Pelagem:
            </p>
            <select
              name=""
              id=""
              style={{ width: '300px' }}
              onChange={handlePelagem}
              value={pelagem}
            >
              <option value="">Selecione a pelagem...</option>
              {coats.map((coat) => (
                <option value={coat.id_veus}>{coat.nome}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Porte:
            </p>
            <select
              name=""
              id=""
              style={{ width: '300px' }}
              onChange={handlePorte}
              value={porte}
            >
              <option value="">Selecione o porte...</option>
              {sizes.map((size) => (
                <option value={size.id_veus}>{size.nome}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Peso:
            </p>
            <input
              type="text"
              value={peso}
              onChange={handlePeso}
              style={{ width: '245px' }}
            />
            <select
              name=""
              id=""
              style={{ width: '50px', marginLeft: '5px', height: '28px' }}
              onChange={handleUnidade}
              value={unidade}
            >
              <>
                <option value="KG">KG</option>
                <option value="GR">GR</option>
              </>
            </select>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleNext}
        >
          Próximo
        </Button1>
      </div>
    </div>
  );
};

export default PetsPage;
