import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import { Button1 } from '../../components/Forms/FormItems';

function ErrorComponent(props) {
  const { errorMessage, error, condition } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '325px',

    borderRadius: '0.8rem',
    '@media screen and (max-width: 600px)': {
      width: '275px',
    },
    // '&:focus-visible': { border: '2px solid #5b7d43' },
    backgroundColor: 'white',
    border: '2px solid #5b7d43',
    boxShadow: 24,
    p: 2.5,
    textAlign: 'justify',
    outline: 'none',
  };

  useEffect(() => {
    if (error != null) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={styleBox}>
        <Typography
          id="keep-mounted-modal-title"
          sx={{
            // width: 'fit-content',
            marginBottom: '1%',
            fontWeight: 600,
          }}
        >
          Erro {error}
        </Typography>
        <Typography
          id="keep-mounted-modal-description"
          sx={{ marginBottom: '4%' }}
        >
          {errorMessage}
          <p style={{ marginTop: '1%', fontWeight: '450' }}>
            Tente novamente mais tarde!
          </p>
        </Typography>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              handleClose();
            }}
            variant="contained"
            sx={{
              fontWeight: 400,
              backgroundColor: '#505050',
              boxShadow: 'none',
              height: '100%',
              width: '100%',
              color: 'white',
              '&:hover': {
                fontWeight: 400,
                backgroundColor: '#505050',
                boxShadow: 'none',
                height: '100%',
                width: '100%',
                color: 'white',
              },
              '&:active': {
                fontWeight: 400,
                backgroundColor: '#808080',
                boxShadow: 'none',
                height: '100%',
                width: '100%',
                color: 'white',
              },
              '&:focus': {
                fontWeight: 400,
                backgroundColor: '#707070',
                boxShadow: 'none',
                height: '100%',
                width: '100%',
                color: 'white',
              },
              // '&:hover': {
              //   fontWeight: 400,
              //   backgroundColor: '#505050',
              //   boxShadow: 'none',
              //   height: '100%',
              //   width: '100%',
              //   color: 'white',
              // },
            }}
          >
            Ok
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
export default ErrorComponent;
