import React, { useEffect, useState } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';

const IntegrationParameters = (props) => {
  const history = useHistory();

  const [aparelho, setAparelho] = useState('');
  const [cliente_id, setCliente_id] = useState('');
  const [cliente_senha, setCliente_senha] = useState('');
  const [empresa_codigo, setEmpresa_codigo] = useState('');
  const [empresa_nome, setEmpresa_nome] = useState('');
  const [empresa_cnpj, setEmpresa_cnpj] = useState('');
  const [unidade_codigo, setUnidade_codigo] = useState('');
  const [unidade_nome, setUnidade_nome] = useState('');
  const [unidade_setor, setUnidade_setor] = useState('');
  const [idCliente, setIdCliente] = useState(0);

  const [infoCliente, setInfoCliente] = useState([]);

  useEffect(() => {
    async function parametersData() {
      try {
        const response = await api.get(`/Clientes/${1}`);
        setInfoCliente(response.data);
      } catch (error) {}
    }
    parametersData();
  }, []);

  const [infoParametros, setInfoParametros] = useState([]);

  useEffect(() => {
    async function parametersData() {
      try {
        const response = await api.get(
          `/ConfigParametros?CNPJ=${infoCliente.cnpj}`,
        );
        setInfoParametros(response.data);
        setAparelho(response.data.aparelho);
        setCliente_id(response.data.cliente_id);
        setCliente_senha(response.data.cliente_senha);
        setEmpresa_codigo(response.data.empresa_codigo);
        setUnidade_setor(response.data.unidade_setor);
        setUnidade_codigo(response.data.unidade_codigo);
        setUnidade_nome(response.data.unidade_nome);
        setIdCliente(response.data.idCliente);
        setEmpresa_cnpj(infoCliente.cnpj);
      } catch (error) {}
    }
    parametersData();
  }, [infoCliente]);

  function handleSave() {
    fetch(`https://www.vetnaweb.com.br/apiveus/ConfigParametros`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        aparelho: aparelho,
        cliente_id: cliente_id,
        cliente_senha: cliente_senha,
        empresa_codigo: empresa_codigo,
        empresa_nome: empresa_nome,
        empresa_cnpj: empresa_cnpj,
        unidade_codigo: unidade_codigo,
        unidade_nome: unidade_nome,
        unidade_setor: unidade_setor,
        idCliente: idCliente,
      }),
    }).then(() => {
      alert('Salvo com sucesso!');
      history.goBack();
    });
  }

  function handleAparelho(event) {
    setAparelho(event.target.value);
  }

  function handleClienteId(event) {
    setCliente_id(event.target.value);
  }

  function handleClienteSenha(event) {
    setCliente_senha(event.target.value);
  }

  function handleEmpresaCodigo(event) {
    setEmpresa_codigo(event.target.value);
  }

  function handleUnidadeSetor(event) {
    setUnidade_setor(event.target.value);
  }

  function handleUnidadeCodigo(event) {
    setUnidade_codigo(event.target.value);
  }

  function handleUnidadeNome(event) {
    setUnidade_nome(event.target.value);
  }

  return (
    <div style={{ marginBottom: '20%', textAlign: 'right' }}>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Parâmetros de Integração com Veus
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Aparelho:
            </p>
            <input
              type="text"
              value={aparelho}
              onChange={handleAparelho}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Cliente_id:
            </p>

            <input
              type="text"
              value={cliente_id}
              onChange={handleClienteId}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Cliente senha:
            </p>
            <input
              type="text"
              value={cliente_senha}
              onChange={handleClienteSenha}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Empresa Código:
            </p>
            <input
              type="text"
              value={empresa_codigo}
              onChange={handleEmpresaCodigo}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Setor:
            </p>
            <input
              type="text"
              value={unidade_setor}
              onChange={handleUnidadeSetor}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Unidade Código:
            </p>
            <input
              type="text"
              value={unidade_codigo}
              onChange={handleUnidadeCodigo}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Unidade Nome:
            </p>
            <input
              type="text"
              value={unidade_nome}
              onChange={handleUnidadeNome}
              style={{ width: '400px', paddingLeft: '5px' }}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleSave}
        >
          Salvar
        </Button1>
      </div>
      <div style={{ height: '80px' }}></div>
    </div>
  );
};

export default IntegrationParameters;
