import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../../services/api';

import { AuthContext } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';
import { appendFileSync } from 'fs';

const OpenReports = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [cotacaoFornecedor, setCotacaoFornecedor] = useState([]);
  const [cotacaoCliente, setCotacaoCliente] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '325px',

    borderRadius: '0.8rem',
    '@media screen and (max-width: 600px)': {
      width: '275px',
    },
    backgroundColor: 'white',
    border: '2px solid #5b7d43',
    boxShadow: 24,
    p: 2.5,
    textAlign: 'justify',
    outline: 'none',
  };

  const toggleOpcoes = (index) => {
    const novasOpcoesAbertas = {};

    Object.keys(opcoesAbertas).forEach((key) => {
      novasOpcoesAbertas[key] = false;
    });

    novasOpcoesAbertas[index] = !opcoesAbertas[index];

    setOpcoesAbertas(novasOpcoesAbertas);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tabelaElement = document.getElementById('tableQuotation');
      if (tabelaElement && !tabelaElement.contains(event.target)) {
        setOpcoesAbertas({});
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);
  const [condition, setCondition] = useState(0);
  const [infoClient, setInfoClient] = React.useState([{}]);

  const [carregado, setCarregado] = useState(false);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apiveus/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setInfoClient(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.email]);

  useEffect(() => {
    api
      .get(
        'https://www.vetnaweb.com.br/apiveus/Validacao?email=' +
          `${user.email}`,
      )
      .then((response) => {
        setInfoClient(response.data);
        localStorage.setItem('email', response.data.emailAcesso);
        setCarregado(true);
      })
      .catch((error) => {
        setError(
          `${error.response.status} - ${getMessageError(
            error.response.status,
          )}`,
        );
        setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
        // eslint-disable-next-line eqeqeq
        setCondition(condition == 0 ? 1 : 0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [aceiteTermos, setAceiteTermos] = useState([]);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apiveus/AceiteTermos?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setAceiteTermos(response.data);
            // setInfoClient(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    api
      .get(
        'https://www.vetnaweb.com.br/apiveus/AceiteTermos?email=' +
          `${user.email}`,
      )
      .then((response) => {})
      .catch((error) => {
        setError(
          `${error.response.status} - ${getMessageError(
            error.response.status,
          )}`,
        );
        setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
        // eslint-disable-next-line eqeqeq
        setCondition(condition == 0 ? 1 : 0);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (carregado) {
      async function clientData() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/cotacao?idParceiro=${infoClient.idAcesso}&status=ATIVO`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setCotacaoCliente(data);
          });
      }
      clientData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const [laudosAbertos, setLaudosAbertos] = useState([]);
  const [laudosAbertos2, setLaudosAbertos2] = useState([]);

  const [nome, setNome] = useState('');
  const [especie, setEspecie] = useState('');
  const [raca, setRaca] = useState('');
  const [pelagem, setPelagem] = useState('');
  const [sexo, setSexo] = useState('');

  const dataAtual = new Date();

  const dia = dataAtual.getDate();
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();

  const diaAtual = dia + '/' + mes + '/' + ano;

  const idAcesso = infoClient.idAcesso;

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const [dataSelecionada, setDataSelecionada] = useState('');

  function formatarData(dataString) {
    const [ano, mes, dia] = dataString.split('-');
    return `${dia}/${mes}/${ano}`;
  }

  const dataNoFormatoOriginal = dataSelecionada;
  const dataFormatada = formatarData(dataNoFormatoOriginal);

  function formatarData2(data) {
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  const dataAtual2 = new Date();
  const dataFormatada2 = formatarData2(dataAtual2);

  useEffect(() => {
    const dataAtual = new Date();
    const dataFormatada = dataAtual.toISOString().split('T')[0];
    setDataSelecionada(dataFormatada);
  }, []);

  const handleDateChange = (e) => {
    const newDataSelecionada = e.target.value;
    setDataSelecionada(newDataSelecionada);

    localStorage.setItem('dataSelecionada', newDataSelecionada);
  };

  useEffect(() => {
    const savedDataSelecionada = localStorage.getItem('dataSelecionada');
    if (savedDataSelecionada) {
      setDataSelecionada(savedDataSelecionada);
    }
  }, []);

  useEffect(() => {
    async function supplierData() {
      try {
        const response = await api.get(
          `/Exames?idParceiro=${infoClient.idAcesso}&dataInicio=${dataFormatada}&dataFinal=${dataFormatada}`,
        );
        setLaudosAbertos(response.data);
        setNoFilter(false);
      } catch (error) {}
    }
    supplierData();
  }, [infoClient, dataFormatada]);

  const [teste, setTeste] = useState([]);

  useEffect(() => {
    async function supplierData3() {
      try {
        const response = await api.get(
          `/Exames?email=${user.email}&dataInicio=01/01/0001&dataFinal=${dataFormatada2}`,
        );
        setLaudosAbertos2(response.data);
        setNoFilter(false);
      } catch (error) {}
    }
    supplierData3();
  }, [user, dataFormatada2]);

  const supplierData2 = async () => {
    try {
      const response = await api.get(
        `/Exames?idParceiro=${infoClient.idAcesso}&dataInicio=${dataFormatada}&dataFinal=${dataFormatada}`,
      );
      setLaudosAbertos(response.data);
      setNoFilter(false);
    } catch (error) {}
  };

  const supplierData4 = async () => {
    try {
      const response = await api.get(
        `/Exames?email=${user.email}&dataInicio=01/01/0001&dataFinal=${dataFormatada2}`,
      );
      setLaudosAbertos2(response.data);
      setNoFilter(false);
    } catch (error) {}
  };

  const [filter, setNoFilter] = useState(false);

  useEffect(() => {
    async function filterData() {
      try {
        const response = await api.get(
          `/Exames?email=${user.email}&nome=${nome}&especie=${especie}&raca=${raca}&pelagem=${pelagem}&sexo=${sexo}`,
        );
        setLaudosAbertos(response.data);
        setLaudosAbertos2(response.data);
        setNoFilter(true);
      } catch (error) {}
    }
    filterData();
  }, [nome, especie, raca, pelagem, user, sexo]);

  const filterData = async () => {
    try {
      const response = await appendFileSync.get(
        `/Exames?email=${
          user.email
        }&nome=${nome}&especie=${especie}&raca=${raca}&pelagem=${pelagem}&sexo=${''}`,
      );
      setLaudosAbertos(response.data);
    } catch (error) {}
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleLinkClick = () => {
    localStorage.removeItem('dadosTutor');
    localStorage.removeItem('dadosPet');
    localStorage.removeItem('dadosRequisitante');
    localStorage.removeItem('dadosExame');

    history.push('/PedidoDeExame/Tutor');
  };

  const handleClick = async (laudo) => {
    try {
      const response = await api.get(
        laudo.recipiente === 'LAUDO'
          ? `/LinkDownloadLaudos?id=${laudo.id}
      `
          : `/laudos?recipiente=${laudo.recipiente}`,
      );
      const data = response.data;

      if (response.data !== '') {
        const response = await api.get(
          laudo.recipiente === 'LAUDO'
            ? `/LinkDownloadLaudos?id=${laudo.id}
      `
            : `/laudos?recipiente=${laudo.recipiente}`,
        );
        const data = response.data;

        // Abrir o PDF automaticamente em uma nova guia
        const newWindow = window.open();
        newWindow.document.write(
          `<iframe src="data:application/pdf;base64,${response.data}" width="100%" height="1000px"></iframe>`,
        );
      } else {
        alert(
          'O resultado ainda não está pronto. Favor tentar novamente mais tarde.',
        );
      }
    } catch (error) {}
  };

  const clearInputValues = () => {
    document.getElementById('nomeInput').value = '';
    document.getElementById('especieInput').value = '';
    document.getElementById('racaInput').value = '';
    document.getElementById('pelagemInput').value = '';
  };

  return (
    <div>
      <h1
        style={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
          marginLeft: '30%',
        }}
      >
        {infoClient && infoClient.TipoAcesso === 'C' ? (
          <>
            <span style={{ margin: '0', marginRight: '20px' }}>Laudos</span>
            <Link
              to={{
                pathname: '/PedidoDeExame/Tutor',
                state: {
                  idAcesso: idAcesso,
                },
              }}
              onClick={handleLinkClick}
            >
              <div>
                <FontAwesomeIcon
                  icon={faPlus}
                  size={12}
                  style={{
                    marginBottom: '5px',
                    color: '#0d214f',
                    fontSize: '24',
                    marginRight: '50px',
                  }}
                />
              </div>
            </Link>
            <input
              type="date"
              value={dataSelecionada}
              onChange={handleDateChange}
              style={{
                marginLeft: '20px',
                marginRight: '200px',
                fontSize: '18px',
                textAlign: 'center',
              }}
              onKeyDown={handleKeyDown}
            />

            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box
                sx={{
                  ...styleBox,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  sx={{
                    // width: 'fit-content',
                    marginBottom: '1%',
                    fontWeight: 600,
                  }}
                >
                  Filtros:
                </Typography>
                <Typography
                  id="keep-mounted-modal-description"
                  sx={{ marginBottom: '4%' }}
                >
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Nome
                    <input
                      type="text"
                      name="nome"
                      id="nomeInput"
                      onChange={(event) => setNome(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Espécie:
                    <input
                      type="text"
                      name="espécie"
                      id="especieInput"
                      onChange={(event) => setEspecie(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Raça:
                    <input
                      type="text"
                      name="raca"
                      id="racaInput"
                      onChange={(event) => setRaca(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Pelagem:
                    <input
                      type="text"
                      name="pelagem"
                      id="pelagemInput"
                      onChange={(event) => setPelagem(event.target.value)}
                    />
                  </p>
                </Typography>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={() => {
                      filterData();
                      handleClose();
                    }}
                    variant="contained"
                    sx={{
                      fontWeight: 400,
                      backgroundColor: '#505050',
                      boxShadow: 'none',
                      height: '100%',
                      width: '100%',
                      color: 'white',
                      '&:hover': {
                        fontWeight: 400,
                        backgroundColor: '#505050',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                      '&:active': {
                        fontWeight: 400,
                        backgroundColor: '#808080',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                      '&:focus': {
                        fontWeight: 400,
                        backgroundColor: '#707070',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
              </Box>
            </Modal>

            <button
              style={{
                marginLeft: '20px',
                marginRight: '50px',
                fontSize: '18px',
                textAlign: 'center',
              }}
              // onClick={() => {
              //   clearInputValues();
              //   if (filter === false) {
              //     handleOpen();
              //   } else if (infoClient.idAcesso != 0) {
              //     supplierData2();
              //   } else {
              //     supplierData4();
              //   }
              // }}
              onClick={() => {
                clearInputValues();
                if (filter === false) {
                  handleOpen();
                } else {
                  supplierData2();
                }
              }}
            >
              {filter === false
                ? 'Filtro'
                : filter === true
                ? 'Limpar Filtro'
                : ''}
            </button>
          </>
        ) : (
          <>
            <p style={{ marginLeft: '20%' }}>Laudos</p>
            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box
                sx={{
                  ...styleBox,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  id="keep-mounted-modal-title"
                  sx={{
                    // width: 'fit-content',
                    marginBottom: '1%',
                    fontWeight: 600,
                  }}
                >
                  Filtros:
                </Typography>
                <Typography
                  id="keep-mounted-modal-description"
                  sx={{ marginBottom: '4%' }}
                >
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Nome
                    <input
                      type="text"
                      name="nome"
                      id="nomeInput"
                      onChange={(event) => setNome(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Espécie:
                    <input
                      type="text"
                      name="espécie"
                      id="especieInput"
                      onChange={(event) => setEspecie(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Raça:
                    <input
                      type="text"
                      name="raca"
                      id="racaInput"
                      onChange={(event) => setRaca(event.target.value)}
                    />
                  </p>
                  <p style={{ marginTop: '1%', fontWeight: '450' }}>
                    Pelagem:
                    <input
                      type="text"
                      name="pelagem"
                      id="pelagemInput"
                      onChange={(event) => setPelagem(event.target.value)}
                    />
                  </p>
                </Typography>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={() => {
                      filterData();
                      handleClose();
                    }}
                    variant="contained"
                    sx={{
                      fontWeight: 400,
                      backgroundColor: '#505050',
                      boxShadow: 'none',
                      height: '100%',
                      width: '100%',
                      color: 'white',
                      '&:hover': {
                        fontWeight: 400,
                        backgroundColor: '#505050',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                      '&:active': {
                        fontWeight: 400,
                        backgroundColor: '#808080',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                      '&:focus': {
                        fontWeight: 400,
                        backgroundColor: '#707070',
                        boxShadow: 'none',
                        height: '100%',
                        width: '100%',
                        color: 'white',
                      },
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
              </Box>
            </Modal>

            <button
              style={{
                marginLeft: '20px',
                marginRight: '50px',
                fontSize: '18px',
                textAlign: 'center',
              }}
              onClick={() => {
                clearInputValues();
                if (filter === false) {
                  handleOpen();
                } else {
                  supplierData4();
                }
              }}
            >
              {filter === false
                ? 'Filtro'
                : filter === true
                ? 'Limpar Filtro'
                : ''}
            </button>
          </>
        )}
      </h1>

      <div style={{ paddingBottom: '5%' }}>
        <table className="estilizada" id="tableQuotation">
          {(infoClient && infoClient.TipoAcesso === 'P') || 'M' ? (
            <thead>
              <tr>
                <th>Clínica</th>
                <th>Animal</th>
                <th>Espécie - Raça - Pelagem</th>
                <th>Data</th>
                <th>Exames</th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr>
                <th>Tutor</th>
                <th>Animal</th>
                <th>Espécie - Raça - Pelagem</th>
                <th>Data Laudo</th>
                <th>Exames</th>
              </tr>
            </thead>
          )}
          <tbody>
            {(infoClient.TipoAcesso === 'P' || infoClient.TipoAcesso === 'M') &&
              laudosAbertos2.map((laudo, index) => (
                <tr
                  style={{
                    backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                  }}
                  onMouseEnter={() => handleRowEnter(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td style={{ cursor: 'pointer' }}>{laudo.tutor_nome}</td>
                  <td style={{ cursor: 'pointer' }}>{laudo.pet_nome}</td>

                  <td style={{ cursor: 'pointer' }}>
                    {' '}
                    {laudo.Especie == null
                      ? laudo.pet_outros_dados
                      : laudo.Especie +
                        ' - ' +
                        laudo.Raca +
                        ' - ' +
                        laudo.Pelagem +
                        laudo.pet_outros_dados}
                  </td>
                  <td style={{ cursor: 'pointer' }}>{laudo.dmaLaudo}</td>
                  <td style={{ cursor: 'pointer' }}>
                    {laudo.recipiente + ' - ' + laudo.exame}
                  </td>
                </tr>
              ))}
          </tbody>
          {/* cotações abertas clientes */}
          <tbody>
            {infoClient.TipoAcesso === 'C' &&
              laudosAbertos.map((laudo, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                  }}
                  onMouseEnter={() => handleRowEnter(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.tutor_nome}
                  </td>

                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.pet_nome}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.Especie == null
                      ? laudo.pet_outros_dados
                      : laudo.Especie +
                        ' - ' +
                        laudo.Raca +
                        ' - ' +
                        laudo.Pelagem +
                        laudo.pet_outros_dados}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.dmaLaudo}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.recipiente + ' - ' + laudo.exame}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {' '}
                    <FontAwesomeIcon
                      onClick={() => toggleOpcoes(index)}
                      style={{ cursor: 'pointer' }}
                      icon={faEllipsisV}
                    />
                  </td>
                  {opcoesAbertas[index] && (
                    <ul
                      style={{
                        marginTop: '37px',
                        marginLeft: '-105px',
                        padding: '10px',
                        borderRadius: '4px',
                        position: 'absolute',
                        zIndex: '999',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(laudo)}
                      >
                        Ver Resultado
                      </li>
                    </ul>
                  )}
                </tr>
              ))}
          </tbody>
          <tbody>
            {infoClient.TipoAcesso === 'M' &&
              laudosAbertos2.map((laudo, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                  }}
                  onMouseEnter={() => handleRowEnter(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.tutor_nome}
                  </td>

                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.pet_nome}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.Especie == null
                      ? laudo.pet_outros_dados
                      : laudo.Especie +
                        ' - ' +
                        laudo.Raca +
                        ' - ' +
                        laudo.Pelagem +
                        laudo.pet_outros_dados}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.dmaLaudo}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.recipiente + ' - ' + laudo.exame}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {' '}
                    <FontAwesomeIcon
                      onClick={() => toggleOpcoes(index)}
                      style={{ cursor: 'pointer' }}
                      icon={faEllipsisV}
                    />
                  </td>
                  {opcoesAbertas[index] && (
                    <ul
                      style={{
                        marginTop: '37px',
                        marginLeft: '-105px',
                        padding: '10px',
                        borderRadius: '4px',
                        position: 'absolute',
                        zIndex: '999',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(laudo)}
                      >
                        Ver Resultado
                      </li>
                    </ul>
                  )}
                </tr>
              ))}
          </tbody>
          <tbody>
            {infoClient.TipoAcesso === 'P' &&
              laudosAbertos2.map((laudo, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                  }}
                  onMouseEnter={() => handleRowEnter(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.tutor_nome}
                  </td>

                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.pet_nome}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.Especie == null
                      ? laudo.pet_outros_dados
                      : laudo.Especie +
                        ' - ' +
                        laudo.Raca +
                        ' - ' +
                        laudo.Pelagem +
                        laudo.pet_outros_dados}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.dmaLaudo}
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleOpcoes(index)}
                  >
                    {laudo.recipiente + ' - ' + laudo.exame}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {' '}
                    <FontAwesomeIcon
                      onClick={() => toggleOpcoes(index)}
                      style={{ cursor: 'pointer' }}
                      icon={faEllipsisV}
                    />
                  </td>
                  {opcoesAbertas[index] && (
                    <ul
                      style={{
                        marginTop: '37px',
                        marginLeft: '-105px',
                        padding: '10px',
                        borderRadius: '4px',
                        position: 'absolute',
                        zIndex: '999',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                      }}
                    >
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(laudo)}
                      >
                        Ver Resultado
                      </li>
                    </ul>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpenReports;
