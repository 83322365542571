import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

const ConfigureEmail = (props) => {
  const history = useHistory();

  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);
  const [cotacaoFornecedor, setCotacaoFornecedor] = useState([]);
  const [configEmail, setConfigEmail] = useState([]);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const idAcesso = clinicalList.idAcesso;

  useEffect(() => {
    if (carregado) {
      async function config() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/ConfigEmail?idParceiro=${idAcesso}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setConfigEmail(data);
            setIsChecked(data?.smtpUseSSL === 'false' ? false : true);
            setAssinatura(data?.sendAssinatura);
            setNome(data?.sendFrom);
            setUsuario(data?.smtpUser);
            setSenha(data?.smtpPassword);
            setSegundos(data?.smtpTime);
            setPorta(data?.smtpPort);
            setServidor(data?.smtpServer);
            setMetodo(data?.smtpUsing);
            setAutenticacao(data?.smtpAuth);
          });
      }
      config();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const [servidor, setServidor] = useState('smtp.seuprovedor.com.br');
  const [porta, setPorta] = useState('587');
  const [assinatura, setAssinatura] = useState('');
  const [usuario, setUsuario] = useState('');
  const [segundos, setSegundos] = useState('30');
  const [nome, setNome] = useState('');
  const [senha, setSenha] = useState('');

  const [metodo, setMetodo] = useState('2');
  const [autenticacao, setAutenticacao] = useState('4');
  const [ssl, setSsl] = useState(true);

  const [isChecked, setIsChecked] = useState(true);

  const [initialValues, setInitialValues] = useState({
    porta: '587',
    metodo: '2',
    autenticacao: '4',
    ssl: 'false',
    segundos: '30',
  });

  const [hasChanges, setHasChanges] = useState(false);

  const handleInputChange = (event) => {
    setIsChecked(!isChecked);
    setSsl(!isChecked);
  };

  const handleServidor = (event) => {
    setServidor(event.target.value);
  };

  const handlePorta = (event) => {
    setPorta(event.target.value);
    setHasChanges(true);
  };

  const handleAssinatura = (event) => {
    setAssinatura(event.target.value);
  };

  const handleUsuario = (event) => {
    setUsuario(event.target.value);
  };

  const handleSegundos = (event) => {
    setSegundos(event.target.value);
    setHasChanges(true);
  };

  const handleNome = (event) => {
    setNome(event.target.value);
  };

  const handleSenha = (event) => {
    setSenha(event.target.value);
  };

  const handleMetodo = (event) => {
    setMetodo(event.target.value);
    setHasChanges(true);
  };

  const handleAutenticacao = (event) => {
    setAutenticacao(event.target.value);
    setHasChanges(true);
  };

  async function handleSave() {
    try {
      await fetch(
        `https://www.vetnaweb.com.br/apicw/ConfigEmail?idParceiro=${idAcesso}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          }, //
          body: JSON.stringify({
            id: configEmail?.id ? configEmail.id : 0,
            idCliente: configEmail?.idCliente
              ? configEmail.idCliente
              : idAcesso,
            sendAssinatura: assinatura,
            sendFrom: nome,
            smtpAuth: hasChanges ? autenticacao : initialValues.autenticacao,
            smtpPassword: senha,
            smtpPort: hasChanges ? porta : initialValues.porta,
            smtpServer: servidor,
            smtpTime: hasChanges ? segundos : initialValues.segundos,
            smtpUseSSL: String(ssl),
            smtpUser: usuario,
            smtpUsing: hasChanges ? metodo : initialValues.metodo,
          }),
        },
      ).then(() => {
        alert('Configurações Salvas com Sucesso.');
        window.location.reload();
      });
    } catch (error) {
      alert(
        'Ocorreu algum erro. Favor aguardar e tentar novamente mais tarde.',
      );
    }
  }

  async function handleSave2() {
    try {
      await fetch(
        `https://www.vetnaweb.com.br/apicw/ConfigEmail?idParceiro=${idAcesso}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          }, //
          body: JSON.stringify({
            id: configEmail?.id ? configEmail.id : 0,
            idCliente: configEmail?.idCliente
              ? configEmail.idCliente
              : idAcesso,
            sendAssinatura: assinatura,
            sendFrom: nome,
            smtpAuth: hasChanges ? autenticacao : initialValues.autenticacao,
            smtpPassword: senha,
            smtpPort: hasChanges ? porta : initialValues.porta,
            smtpServer: servidor,
            smtpTime: hasChanges ? segundos : initialValues.segundos,
            smtpUseSSL: String(ssl),
            smtpUser: usuario,
            smtpUsing: hasChanges ? metodo : initialValues.metodo,
          }),
        },
      ).then(() => {
        alert('Configurações Salvas com Sucesso.');
      });
    } catch (error) {
      alert(
        'Ocorreu algum erro. Favor aguardar e tentar novamente mais tarde.',
      );
    }
  }

  async function handleTest() {
    await handleSave2();
    fetch(
      `https://www.vetnaweb.com.br/apicw/EmailTeste?idParceiro=${idAcesso}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          id: configEmail?.id ? configEmail.id : 0,
          idCliente: configEmail?.idCliente ? configEmail.idCliente : idAcesso,
          sendAssinatura: configEmail?.sendAssinatura
            ? configEmail.sendAssinatura
            : assinatura,
          sendFrom: configEmail?.sendFrom ? configEmail.sendFrom : nome,
          smtpAuth: configEmail?.smtpAuth ? configEmail.smtpAuth : 4,
          smtpPassword: configEmail?.smtpPassword
            ? configEmail.smtpPassword
            : senha,
          smtpPort: configEmail?.smtpPort ? configEmail.smtpPort : porta,
          smtpServer: configEmail?.smtpServer
            ? configEmail.smtpServer
            : servidor,
          smtpTime: configEmail?.smtpTime ? configEmail.smtpTime : segundos,
          smtpUseSSL: String(ssl),
          smtpUser: configEmail?.smtpUser ? configEmail.smtpUser : usuario,
          smtpUsing: configEmail?.smtpUsing ? configEmail.smtpUsing : 2,
        }),
      },
    ).then(() => {
      history.push(`/TesteEmail`);
    });
  }

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Configurar E-mail
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: '5%',
          }}
        >
          {' '}
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Servidor SMTP:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={servidor}
              onChange={handleServidor}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
              marginRight: '40.5%',
            }}
          >
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#0d214f',
              }}
            >
              Porta SMTP:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '75px',
                borderRadius: '5px',
                textAlign: 'right',
                paddingRight: '10px',
              }}
              type="text"
              value={porta}
              onChange={handlePorta}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Método de Envio:
            </p>
            <select
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              value={metodo}
              onChange={handleMetodo}
            >
              <option value="1">Local</option>
              <option value="2">OutLook</option>
              <option value="3">Exchange</option>
            </select>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Tipo de Autenticação:
            </p>
            <select
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              value={autenticacao}
              onChange={handleAutenticacao}
            >
              <option value="1">Anônimo</option>
              <option value="2">Básico</option>
              <option value="3">NTLM</option>
              <option value="4">Ambos</option>
            </select>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
              marginRight: '51.5%',
            }}
          >
            <p
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: '#0d214f',
                whiteSpace: 'nowrap',
              }}
            >
              Usar SSL no Envio de E-mails:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '25px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              checked={isChecked}
              onChange={handleInputChange}
              type="checkbox"
              value={isChecked}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
              marginRight: '40%',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Timeout (segundos):
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '75px',
                borderRadius: '5px',
                paddingRight: '10px',
              }}
              type="text"
              value={segundos}
              onChange={handleSegundos}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Nome de Apresentação:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={nome}
              onChange={handleNome}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Assinatura:
            </p>
            <textarea
              style={{
                marginTop: '5px',
                borderRadius: '5px',
                paddingLeft: '10px',
                paddingTop: '5px',
                marginLeft: '10px',
              }}
              name="observacao"
              id="observacao"
              cols="26"
              rows="5"
              value={assinatura}
              onChange={handleAssinatura}
              placeholder="Assinatura do seu e-mail"
            ></textarea>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Usuário:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={usuario}
              onChange={handleUsuario}
              placeholder="seu.e-mail@provedor.com.br"
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Senha:
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="password"
              value={senha}
              onChange={handleSenha}
            />
          </div>
        </div>
        <Button1 onClick={handleTest}>Teste de E-mail</Button1>
        <div style={{ margin: '30px auto' }}>
          <Button1
            style={{
              display: 'inline-block',
              width: '47%',
            }}
            onClick={handleSave}
          >
            Salvar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>{' '}
        </div>
      </div>
    </div>
  );
};

export default ConfigureEmail;
