import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Input from '../../../components/Forms/Input';
import { Button1 } from '../../../components/Forms/FormItems';
import useForm from '../../../Hooks/useForm';
import Error from '../../../helper/Error';

import { AuthContext } from '../../../contexts/AuthContext';
import styles from '../../../styles/LoginForm.module.css';
import stylesBtn from '../../../styles/GlobalCss.module.css';
import google from '../../../icons/google.png';
import Stack from '@material-ui/core/Stack';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Alert } from '@material-ui/core';

const LoginPage = () => {
  const email = useForm({});
  const password = useForm({});

  const history = useHistory();

  const {
    signIn,
    loadingAuth,
    click,
    setLoadingAuth,
    getMessageByErrorCode,
    setClick,
    loginGoogle,
    setLogged,
  } = React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    signIn(email.value, password.value).then((value) => {
      setClick(true);

      value && setError(` - ${value.code}`);
      value && setErrorMessage(getMessageByErrorCode(value.code));
    });
  }

  function handleLogged(event) {
    if (event) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }

  function onChangeScreen() {
    history.push('/Cadastrar');
    setClick(true);
  }
  document.body.style.backgroundColor = 'white';
  React.useEffect(() => {
    setClick(null);
    setLoadingAuth(false);
    window.scrollTo(0, 0);

    return () => {
      setClick(null);
      setLoadingAuth(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleHover(event) {
    event.preventDefault();
    document.getElementById('mostrador').style.display = 'block';
  }

  function handleLeave(event) {
    event.preventDefault();

    document.getElementById('mostrador').style.display = 'none';
  }

  return (
    <section className="animeLeft">
      <h1 className="title" style={{ textAlign: 'left', color: 'black' }}>
        Login
      </h1>
      <form
        onSubmit={handleSubmit}
        onChange={() => {
          setClick(false);
          setErrorMessage(null);
          setError(null);
        }}
      >
        <Input label="E-mail" name="email" {...email} />

        <Input label="Senha" type="password" name="password" {...password} />
        <FormGroup sx={{ display: 'block !important' }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  handleLogged(event.target.checked);
                }}
                sx={{
                  paddingTop: '0',
                  paddingBottom: '0',

                  background: 'unset !important',
                }}
              />
            }
            label=" Mantenha-me Conectado "
          />
          <FontAwesomeIcon
            icon={faQuestion}
            style={{
              color: 'grey',
              borderRadius: '20px',
              paddingTop: '0.5%',
            }}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
          />
        </FormGroup>
        <div
          id="mostrador"
          style={{
            display: 'none',
            position: 'absolute',
            transform: 'translate(5%, -125%)',
            width: '385px',
            height: '85px',
            opacity: '97%',
            padding: '0.3%',
            fontWeight: 'bold',
            background: 'grey',
            color: 'white',
            borderRadius: '8px',
          }}
        >
          Mantem o acesso salvo quando a opção estiver marcada, caso contrário
          mantem até o fechamento do NAVEGADOR.
        </div>

        {loadingAuth ? (
          <Button1 personalizado={styles.personalizado1} disabled>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Carregando...
            </Stack>
          </Button1>
        ) : (
          <Button1>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Entrar
            </Stack>
          </Button1>
        )}
      </form>
      <div style={{ margin: '2.5% 0 0' }}>
        {click && error === undefined && email.value !== '' ? (
          <Alert>
            <h6>Login Efetuado com Sucesso</h6>
          </Alert>
        ) : null}
        <div className={styles.containerError}>
          <Error error={errorMessage} />
        </div>
      </div>
      <Link className={styles.perdeu} to="/RecuperarSenha">
        Esqueceu a Senha?
      </Link>
      <div className={styles.cadastro}>
        <h2
          className={styles.subtitle}
          style={{ textAlign: 'left', color: 'black' }}
        >
          Cadastre-se
        </h2>
        <p>Ainda não possui conta? Cadastre-se no site.</p>

        <div style={{ display: 'flex' }}>
          <button
            className={stylesBtn.button1}
            onClick={onChangeScreen}
            style={{ marginRight: '10px' }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Cadastrar
            </Stack>
          </button>
        </div>
      </div>
      <div className={styles.cadastro}>
        {loadingAuth ? (
          <button
            className={stylesBtn.buttonGoogle}
            onClick={() => {}}
            disabled
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <img
                src={google}
                alt="Carregando..."
                className="img-google"
                width={30}
                height={30}
              />
              Carregando...
            </Stack>
          </button>
        ) : (
          <button
            className={stylesBtn.buttonGoogle}
            onClick={() => loginGoogle()}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{ alignItems: 'center', justifyContent: 'center' }}
            >
              <img
                src={google}
                alt="Login com Google"
                className="img-google"
                width={30}
                height={30}
              />
              Login com Google
            </Stack>
          </button>
        )}
      </div>
    </section>
  );
};

export default LoginPage;
