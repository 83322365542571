import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const ViewCSV = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const base64Tratada = id.replace(/%.*/, '');

  const [visualizacao, setVisualizacao] = useState([]);

  const idCliente = props.location.state.idCliente;

  const idCotacao = props.location.state.idCotacao;

  useEffect(() => {
    async function base64() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/UploadXLS?tipo=CSV`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
          body: base64Tratada,
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setVisualizacao(data);
        });
    }
    base64();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function handleConfirm() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/UploadXLS?idParceiro=${idCliente}&idCotacao=${idCotacao}&tipo=CSV`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: base64Tratada,
      },
    )
      .then((response) => {
        if (response.status === 200) {
          history.goBack();
        } else if (response.status === 500) {
          throw new Error(
            'O servidor encontrou um erro ao processar a requisição.',
          );
        } else {
          throw new Error('A resposta da requisição não foi bem-sucedida.');
        }
      })
      .catch((error) => {
        alert('Ocorreu um erro ao processar a requisição.');
      });
  }

  return (
    <div style={{ paddingBottom: '5%' }}>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Confirmar envio dos itens?
      </h2>
      <table className="estilizada2">
        <thead>
          <tr>
            <th>Descrição</th>
            <th>Código de Barras</th>
            <th>Quantidade</th>
            <th>Unidade</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(visualizacao) ? (
            visualizacao.map((visualizar, index) => (
              <tr key={index}>
                <td>{visualizar.coluna01}</td>
                <td>{visualizar.coluna02}</td>
                <td>{visualizar.coluna03}</td>
                <td>{visualizar.coluna04}</td>
                <td>{visualizar.coluna05}</td>
              </tr>
            ))
          ) : (
            <tr>
              <p
                style={{
                  marginTop: '40px',
                  fontSize: '20px',
                  marginLeft: '20px',
                }}
              >
                O arquivo não está no formato adequado, favor revisar e tentar
                novamente.
              </p>
            </tr>
          )}
        </tbody>
      </table>

      <div
        style={{
          textAlign: 'center',
          marginTop: '40px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        <Button1
          style={{
            display: 'inline-block',
            width: '17%',
          }}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button1>
        <Button1
          style={{
            display: 'inline-block',
            marginLeft: '40px',
            width: '17%',
          }}
          onClick={handleConfirm}
        >
          Confirmar
        </Button1>{' '}
      </div>
    </div>
  );
};

export default ViewCSV;
