import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const CloseQuotation = (props) => {
  const history = useHistory();

  const [info, setInfo] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function info() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/Cotacao/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setInfo(data);
        });
    }
    info();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/CotacaoEncerrar?idParceiro=${info.idCliente}&idCotacao=${id}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          idParceiro: info.idCliente,
          idCotacao: id,
        }),
      },
    ).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h2
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        Encerrar Cotação
      </h2>
      <h4
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        {info.descricao} - Válida até{' '}
        {info.data
          ?.split('T')[0]
          .split('-')
          .join('/')
          .replace(/^(\d{4})\/(\d{2})\/(\d{2}).*$/, '$3/$2/$1')}{' '}
        às {info.horario?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2')}
        {'h'}
      </h4>
      <h3
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '20px',
        }}
      >
        Tem certeza que deseja encerrar essa cotação?
      </h3>
      <div
        style={{
          margin: '50px auto',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button1
          style={{
            display: 'inline-block',
            width: '10%',
          }}
          onClick={handleClose}
        >
          Sim
        </Button1>
        <Button1
          style={{
            display: 'inline-block',
            marginLeft: '15px',
            width: '10%',
          }}
          onClick={() => history.goBack()}
        >
          Não
        </Button1>
      </div>
    </div>
  );
};

export default CloseQuotation;
