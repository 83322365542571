import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';
import { Email } from '@material-ui/icons';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';

const AttendantRegistration = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [nomeAtendente, setNomeAtendente] = useState('');
  const [email, setEmail] = useState('');
  const [crmv, setCrmv] = useState('');

  const [atendentes, setAtendentes] = useState('');

  const { idAcesso } = props.location.state;

  useEffect(() => {
    async function AttendantInfo() {
      try {
        const response = await api.get(`/Atendentes?idParceiro=${idAcesso}`);
        setAtendentes(response.data);
      } catch (error) {}
    }
    AttendantInfo();
  }, [idAcesso]);

  const handleNome = (event) => {
    setNomeAtendente(event.target.value);
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleCrmv = (event) => {
    setCrmv(event.target.value);
  };

  const [infoAttendant, setInfoAttendant] = useState('');

  useEffect(() => {
    async function supplierData() {
      try {
        const response = await api.get(`/Atendentes`);
        setInfoAttendant(response.data);
      } catch (error) {}
    }
    supplierData();
  }, []);

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
          color: '#0d214f',
        }}
      >
        Cadastro de Atendentes
        <Link
          to={{
            pathname: '/Cadastro/Atendente/Adicionar',
            state: {
              idAcesso: idAcesso,
            },
          }}
          style={{
            display: 'inline-block',
            marginLeft: '15px',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              height: 'max-content',
              alignSelf: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={12}
              style={{
                color: '#0d214f',
                fontSize: '24',
              }}
            />
          </div>
        </Link>
      </h2>
      <div style={{ paddingBottom: '5%' }}>
        <table className="estilizada" id="tableQuotation">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CRMV</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {atendentes &&
              atendentes?.map((atendente, index) => (
                <tr>
                  <td style={{ cursor: 'pointer' }}>{atendente.nome}</td>
                  <td style={{ cursor: 'pointer' }}>{atendente.crmv}</td>
                  <td style={{ cursor: 'pointer' }}>
                    <Link
                      to={{
                        pathname: `/Cadastro/Atendente/Editar/${atendente.id}`,
                        state: {
                          idAcesso: idAcesso,
                        },
                      }}
                      style={{
                        display: 'inline-block',
                        marginLeft: '15px',
                      }}
                    >
                      <div
                        style={{
                          justifyContent: 'center',
                          height: 'max-content',
                          alignSelf: 'center',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          size={12}
                          style={{
                            color: '#0d214f',
                            fontSize: '24',
                          }}
                        />
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttendantRegistration;
