import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';

// import $ from 'jquery';
// import Footer from './components/Footer';
import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes/index';
// import HeaderUser from './components/HeaderUser';
// import { conectado } from './services/firebaseConnection';

function App() {
  // const { setUser } = React.useContext(AuthContext);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Route path="/">
          <Routes />
        </Route>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
