import React from 'react';
import styles from '../styles/Footer.module.css';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <p>© 2023 | Vet Laudos - Todos os direitos reservados.</p>
    </footer>
  );
};
export default Footer;
