import axios from 'axios';

const api = axios.create({
  baseURL: 'https://www.vetnaweb.com.br/apiveus',

  headers: {
    Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
  },
});

export const apivc = axios.create({
  baseURL: 'https://www.vetnaweb.com.br/api',

  headers: {
    Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
  },
});

export default api;
