import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const QuotationClientEdit = (props) => {
  const history = useHistory();

  const { id } = useParams();

  const [infoClient, setInfoClient] = useState([]);

  const currentDate = new Date().toISOString().split('T')[0];

  const [descricao, setDescricao] = useState([]);
  const [data, setData] = useState([]);
  const [validade, setValidade] = useState([]);
  const [horario, setHorario] = useState([]);

  const handleDescricao = (event) => {
    setDescricao(event.target.value);
  };

  const handleData = (event) => {
    setData(event.target.value);
  };

  const handleValidade = (event) => {
    setValidade(event.target.value);
  };

  const handleHorario = (event) => {
    setHorario(event.target.value);
  };

  useEffect(() => {
    async function infoClient() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/Cotacao/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setInfoClient(data);
          setDescricao(data ? data.descricao : '');
          setData(data ? data.data?.split('T')[0] : '');
          setValidade(data ? data.validade?.split('T')[0] : '');
          setHorario(
            data
              ? data.horario?.replace(/^(\d{2})(\d{2})(\d{2})$/, '$1:$2')
              : '00:00',
          );
        });
    }
    infoClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSave() {
    fetch(
      `https://www.vetnaweb.com.br/apicw/Cotacao?idParceiro=${infoClient.idCliente}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
        },
        body: JSON.stringify({
          id: id,
          idCliente: infoClient.idCliente,
          descricao: descricao,
          data: data,
          validade: validade,
          horario: horario,
        }),
      },
    ).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h1
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'center',
          color: '#0d214f',
          marginBottom: '40px',
        }}
      >
        Editar Cotação
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginBottom: '20px',
          }}
        >
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Descrição:{' '}
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '200px',
              borderRadius: '5px',
              textAlign: 'left',
              paddingLeft: '10px',
            }}
            type="text"
            value={descricao}
            onChange={handleDescricao}
          />
        </div>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#0d214f',
              marginLeft: '35px',
            }}
          >
            Data:{' '}
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '200px',
              borderRadius: '5px',
              textAlign: 'right',
              paddingRight: '10px',
            }}
            type="date"
            value={data}
            onChange={handleData}
          />
        </div>
        <div
          style={{ display: 'flex', marginBottom: '20px', marginLeft: '5px' }}
        >
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Validade:{' '}
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '200px',
              borderRadius: '5px',
              textAlign: 'right',
              paddingRight: '10px',
            }}
            type="date"
            value={validade}
            onChange={handleValidade}
          />
        </div>
        <div
          style={{ display: 'flex', marginBottom: '20px', marginLeft: '10px' }}
        >
          <p style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}>
            Horário:{' '}
          </p>
          <input
            style={{
              marginLeft: '5px',
              width: '200px',
              borderRadius: '5px',
              textAlign: 'right',
              paddingRight: '10px',
            }}
            type="time"
            value={horario}
            onChange={handleHorario}
          />
        </div>
        <div style={{ margin: '20px auto' }}>
          <Button1
            style={{
              display: 'inline-block',
              width: '47%',
            }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={handleSave}
          >
            Salvar
          </Button1>{' '}
        </div>
      </div>
    </div>
  );
};

export default QuotationClientEdit;
