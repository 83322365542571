import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

import api from '../../services/api';

import { AuthContext } from '../../contexts/AuthContext';

import OpenReports from '../OpenReports/OpenReports';

const AcceptanceOfTerms = (props) => {
  const history = useHistory();

  const {
    user,
    getMessageByErrorCodeAxios,
    getMessageError,
    verifyUserLoged,
    signOutFunc,
  } = React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);
  const [condition, setCondition] = useState(0);

  const [aceiteTermos, setAceiteTermos] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [checkbox3Checked, setCheckbox3Checked] = useState(false);

  const [allCheckboxesChecked, setAllCheckboxesChecked] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (checkbox1Checked && checkbox2Checked && checkbox3Checked) {
      setAllCheckboxesChecked(true);
    } else {
      setAllCheckboxesChecked(false);
    }
  }, [checkbox1Checked, checkbox2Checked, checkbox3Checked]);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apiveus/AceiteTermos?eMail=' +
              `${user.email}`,
          )
          .then((response) => {
            setAceiteTermos(response.data);
            setLoading(false);
          })
          .catch((error) => {
            setError(
              `${error.response?.status} - ${getMessageError(
                error.response?.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response?.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
            setLoading(false);
          });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function logout() {
    signOutFunc();
    setAnchorEl(null);
  }

  function handleAccept() {
    if (allCheckboxesChecked) {
      fetch(
        `https://www.vetnaweb.com.br/apiveus/AceiteTermos?email=${user.email}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
          body: JSON.stringify({}),
        },
      )
        .then(() => <OpenReports {...props} />)
        .then(() => window.location.reload());
    } else {
      alert(
        'Para prosseguir é necessário que todas as opções estejam marcadas.',
      );
    }
  }

  return (
    <div>
      {loading ? null : aceiteTermos !== null ? (
        aceiteTermos === 'true' ? (
          <OpenReports {...props} />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <h1
              style={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '40px',
                color: '#0d214f',
              }}
            >
              Termos de Uso
            </h1>
            <h4
              style={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '40px',
                color: '#0d214f',
              }}
            >
              Para utilizar o nosso sistema, você precisa concordar com os
              termos e condições de uso.
            </h4>
            <div
              style={{
                marginTop: '20px',
                marginBottom: '40px',
                color: '#0d214f',
                display: 'inline-block',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  href="http://www.cotanaweb.com.br/TermosDeUso/TermodeUsoUsuarioFinalAPPCotaNaWeb.pdf"
                  alt="Termo de Uso"
                  style={{ textDecoration: 'underline' }}
                >
                  Termos de Uso
                </a>
                <input
                  type="checkbox"
                  style={{ marginLeft: '10px' }}
                  checked={checkbox1Checked}
                  onChange={(e) => setCheckbox1Checked(e.target.checked)}
                />{' '}
                <p style={{ marginLeft: '5px' }}>
                  Li e concordo com os Termos de Uso.
                </p>
              </div>
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <a
                  href="http://www.cotanaweb.com.br/TermosDeUso/TermodeUsoUsuarioFinalAPPCotaNaWeb.pdf"
                  alt="Política de Privacidade"
                  style={{ textDecoration: 'underline' }}
                >
                  Política de Privacidade
                </a>
                <input
                  type="checkbox"
                  style={{ marginLeft: '10px' }}
                  checked={checkbox2Checked}
                  onChange={(e) => setCheckbox2Checked(e.target.checked)}
                />{' '}
                <p style={{ marginLeft: '5px' }}>
                  Li e concordo com a Política de Privacidade.
                </p>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type="checkbox"
                  style={{ marginTop: '20px' }}
                  checked={checkbox3Checked}
                  onChange={(e) => setCheckbox3Checked(e.target.checked)}
                />
                <p style={{ marginLeft: '5px', marginTop: '20px' }}>
                  Concordo que li todos os termos e estou de acordo.
                </p>
              </div>
            </div>
            <div style={{ margin: '20px auto' }}>
              <Button1
                style={{
                  display: 'inline-block',
                  width: '17%',
                }}
                onClick={handleAccept}
              >
                Aceitar
              </Button1>
              <Button1
                style={{
                  display: 'inline-block',
                  marginLeft: '10px',
                  width: '17%',
                }}
                onClick={() => logout()}
              >
                Cancelar
              </Button1>
            </div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default AcceptanceOfTerms;
