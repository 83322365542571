import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Profile from '../pages/Profile';
import HeaderUser from '../components/HeaderUser';
import NotFound from '../pages/NotFound';
import ListOfClinics from '../pages/Clinics/ListOfClinics';
import ClinicalDetails from '../pages/Clinics/ClinicalDetails';
import Footer from '../components/Footer';
import { AuthContext } from '../contexts/AuthContext';
import OpenReports from '../pages/OpenReports/OpenReports';
import QuotationDetails from '../pages/QuotationDetails/QuotationDetails';
import LaunchQuotePrices from '../pages/LaunchQuotePrices/LaunchQuotePrices';
import QuotationEdit from '../pages/QuotationEdit/QuotationEdit';
import AddQuotation from '../pages/AddQuotation/AddQuotation';
import QuotationItens from '../pages/QuotationItens/QuotationItens';
import QuotationClientEdit from '../pages/QuotationClientEdit/QuotationClientEdit';
import Result from '../pages/Result/Result';
import CloseQuotation from '../pages/CloseQuotation/CloseQuotation';
import AddQuotationItens from '../pages/AddQuotationItens/AddQuotationItens';
import EditQuotationItens from '../pages/EditQuotationItens/EditQuotationItens';
import DeleteItemQuotation from '../pages/DeleteItemQuotation/DeleteItemQuotation';
import ItemQuotation from '../pages/ItemQuotation/ItemQuotation';
import ViewCSV from '../pages/ViewCSV/ViewCSV';
import EditItemQuotation from '../pages/EditItemQuotation/EditItemQuotation';
import SupplierRegistration from '../pages/SupplierRegistration/SupplierRegistration';
import ListCloseQuotation from '../pages/ListCloseQuotation/ListCloseQuotation';
import ConfigureEmail from '../pages/ConfigureEmail/ConfigureEmail';
import EmailTest from '../pages/EmailTest/EmailTest';
import ErrorEmailPage from '../pages/ErrorEmailPage/ErrorEmailPage';
import ConfigureWhatsApp from '../pages/ConfigureWhatsApp/ConfigureWhatsApp';
import WhatsAppSettings from '../pages/WhatsAppSettings/WhatsAppSettings';
import WhatsAppTools from '../pages/WhatsAppTools/WhatsAppTools';
import AddSupplier from '../pages/AddSupplier/AddSupplier';
import EditSupplier from '../pages/EditSupplier/EditSupplier';
import SendWhatsApp from '../pages/SendWhatsApp/SendWhatsApp';
import TesteDownload from '../pages/TesteDownload/TesteDownload';
import SendEmail from '../pages/SendEmail/SendEmail';
import AddBuyer from '../pages/AddBuyer/AddBuyer';
import AcceptanceOfTerms from '../pages/AcceptanceOfTerms/AcceptanceOfTerms';
import UpdateScreen from '../pages/UpddateScreen/UpdateScreen';
import ListWithAllPrices from '../pages/ListWithAllPrices/ListWithAllPrices';
import OrdersBySupplier from '../pages/OrdersBySupplier/OrdersBySupplier';
import PetsPage from '../pages/PetsPage/PetsPage';
import TutorPage from '../pages/TutorPage/TutorPage';
import RequesterPage from '../pages/RequesterPage/RequesterPage';
import ExamPage from '../pages/ExamPage/ExamPage';
import ExamResult from '../pages/ExamResult/ExamResult';
import CompanyParameters from '../pages/CompanyParameters/CompanyParameters';
import IntegrationParameters from '../pages/IntegrationParameters/IntegrationParameters';
import AttendantRegistration from '../pages/AttendantRegistration/AttendantRegistration';
import AddAttendant from '../pages/AttendantRegistration/AddAttendant';
import EditAttendant from '../pages/AttendantRegistration/EditAttendant';

function AppRoutes() {
  const { verifyUserLoged } = useContext(AuthContext);
  function cookieOff() {
    verifyUserLoged();
  }

  const history = createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path="/PedidoDeExame/Tutor"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <TutorPage {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/PedidoDeExame/Animal"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <PetsPage {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/PedidoDeExame/Solicitante"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <RequesterPage {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/PedidoDeExame/Exame"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ExamPage {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AcceptanceOfTerms {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ResultadoExame/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ExamResult {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ParametrosDaEmpresa"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <CompanyParameters {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ParametrosDeIntegracao"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <IntegrationParameters {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ItensCotacao/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <QuotationItens {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EditarCotacao/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <QuotationClientEdit {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/CadastrarFornecedor"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <SupplierRegistration {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/AdicionarFornecedor"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AddSupplier {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EditarFornecedor/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <EditSupplier {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/CotacoesEncerradas"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ListCloseQuotation {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ConfigurarEmail"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ConfigureEmail {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/TesteEmail"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <EmailTest {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/TesteEmailErro"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ErrorEmailPage {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ConfigurarWhatsApp"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ConfigureWhatsApp {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ConfiguracoesWhatsApp"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <WhatsAppSettings {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/FerramentasWhatsApp"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <WhatsAppTools {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EnviarWhatsApp/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <SendWhatsApp {...props} />
              <Footer />
            </>
          )}
        />

        <Route
          exact
          path="/EnviarEmail/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <SendEmail {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/AdicionarItemCotacao/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AddQuotationItens {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EditarCotacaoItem/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <EditQuotationItens {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/ExcluirItemCotacao/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <DeleteItemQuotation {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Cotacoes/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ItemQuotation {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EditarPreco/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <EditItemQuotation {...props} />
              <Footer />
            </>
          )}
        />

        <Route
          exact
          path="/Resultado/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <Result {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/EncerrarCotacao/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <CloseQuotation {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/LancarPrecos/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <QuotationEdit {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/VisualizacaoCSV/file=:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ViewCSV {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/MeuPerfil/"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <Profile {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Relatorio/ListagemDeResultado/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <TesteDownload {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Relatorio/ListagemComTodosOsPrecos/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <ListWithAllPrices {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Relatorio/PedidosPorFornecedor/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <OrdersBySupplier {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Atualizacao"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <UpdateScreen {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Cadastro/Atendente"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AttendantRegistration {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Cadastro/Atendente/Adicionar"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AddAttendant {...props} />
              <Footer />
            </>
          )}
        />
        <Route
          exact
          path="/Cadastro/Atendente/Editar/:id"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <EditAttendant {...props} />
              <Footer />
            </>
          )}
        />
        {/* <Route
          exact
          path="/Cadastro"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <AddBuyer {...props} />
              <Footer />
            </>
          )}
        /> */}

        <Route
          exact
          path="*"
          render={(props) => (
            <>
              {cookieOff()}
              <HeaderUser {...props} />
              <NotFound {...props} />
              <Footer />
            </>
          )}
        />
      </Switch>
      <Footer />
    </Router>
  );
}

export default AppRoutes;
