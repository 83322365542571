import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBMJQIQp4CahnYQVROQjakdMnULc9PTaQ8',
  authDomain: 'vetlaudos-78e15.firebaseapp.com',
  projectId: 'vetlaudos-78e15',
  storageBucket: 'vetlaudos-78e15.appspot.com',
  messagingSenderId: '596994950943',
  appId: '1:596994950943:web:683900c231f4054e11522a',
  measurementId: 'G-3S84KRQWWC',
};

export function firebaseConnect() {
  initializeApp(firebaseConfig);
}
