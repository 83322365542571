import React, { useState } from 'react';
import Input from '../../../components/Forms/Input';
import { Button1 } from '../../../components/Forms/FormItems';
import useForm from '../../../Hooks/useForm';
import { AuthContext } from '../../../contexts/AuthContext';
import Error from '../../../helper/Error';
import styles from '../../../styles/LoginForm.module.css';
import Spinner from 'react-bootstrap/Spinner';
import Alert from '@material-ui/core/Alert';
import Stack from '@material-ui/core/Stack';

const LoginPasswordLost = () => {
  const email = useForm({});
  const { loadingAuth, forgot, getMessageByErrorCode, click, setClick } =
    React.useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();

    forgot(email.value).then((value) => {
      setClick(true);
      value && setError(` - ${value.code}`);
      value && setErrorMessage(getMessageByErrorCode(value.code));
    });
  }
  React.useEffect(() => {
    setClick(false);

    window.scrollTo(0, 0);

    return () => {
      setClick(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="animeLeft">
      <h1 className="title">Esqueceu a senha?</h1>

      <form
        onSubmit={handleSubmit}
        onChange={() => {
          setClick(false);
          setErrorMessage(null);
          setError(null);
        }}
      >
        <Input label="Email" name="email" {...email} />
        {loadingAuth ? (
          <Button1 variant="success" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              {/* <img src={google} className="img-google" width={30} height={30} /> */}
              {/* <CircularProgress color="success" /> */}
              Enviado...
            </Stack>
          </Button1>
        ) : (
          <Button1>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Enviar E-mail
            </Stack>
          </Button1>
        )}
      </form>

      <div style={{ margin: '2.5% 0 0' }}>
        {
          // eslint-disable-next-line eqeqeq
          click && error == undefined && email.value !== '' ? (
            <Alert>
              <h6>E-mail enviado com sucesso</h6>
              <p>Verifique sua caixa de Email ou Spam.</p>
            </Alert>
          ) : null
        }
        <div className={styles.containerError}>
          <Error error={errorMessage} />
        </div>
      </div>
    </section>
  );
};

export default LoginPasswordLost;
