import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

const ItemQuotation = (props) => {
  const { id } = useParams();

  const idCliente = props.location.state.idCliente;

  const history = useHistory();

  const [item, setItem] = useState([]);
  const [cotacoes, setCotacoes] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState({});

  const toggleOpcoes = (index) => {
    const novasOpcoesAbertas = {};

    Object.keys(opcoesAbertas).forEach((key) => {
      novasOpcoesAbertas[key] = false;
    });

    novasOpcoesAbertas[index] = !opcoesAbertas[index];

    setOpcoesAbertas(novasOpcoesAbertas);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tabelaElement = document.getElementById('tableQuotation');
      if (tabelaElement && !tabelaElement.contains(event.target)) {
        setOpcoesAbertas({});
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function item() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/CotacaoItens/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setItem(data[0]);
        });
    }
    item();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function cotacoes() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/Precos?idCotacao=${2}&idItem=${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setCotacoes(data);
        });
    }
    cotacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Preços da Cotação
      </h1>
      <table className="estilizada2" id="tableQuotation">
        <thead>
          <tr>
            <th>Fornecedor</th>
            <th>Preço</th>
            <th>Observação</th>
          </tr>
        </thead>
        <tbody>
          {cotacoes &&
            cotacoes?.map((cotacao, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                }}
                onMouseEnter={() => handleRowEnter(index)}
                onMouseLeave={handleRowLeave}
              >
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {cotacao.empresa}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {cotacao.preco.toFixed(2)}
                </td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => toggleOpcoes(index)}
                >
                  {cotacao.observacao}
                </td>
                <td style={{ textAlign: 'center', width: '3%' }}>
                  <FontAwesomeIcon
                    onClick={() => toggleOpcoes(index)}
                    style={{ cursor: 'pointer' }}
                    icon={faEllipsisV}
                  />
                </td>
                {opcoesAbertas[index] && (
                  <ul
                    style={{
                      marginTop: '37px',
                      marginLeft: '-72.5px',
                      padding: '10px',
                      borderRadius: '4px',
                      position: 'absolute',
                      zIndex: '999',
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/EditarPreco/${cotacao.id}`, {
                          idFornecedor: cotacao.idfornecedor,
                          idCotacao: cotacao.idcotacao,
                          idItem: cotacao.iditem,
                        });
                      }}
                    >
                      Editar
                    </li>
                  </ul>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemQuotation;
