import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

import Input from '../../components/Forms/Input';

import useForm from '../../Hooks/useForm';
import Error from '../../helper/Error';
import styles from '../../styles/LoginForm.module.css';
import Spinner from 'react-bootstrap/Spinner';
import Stack from '@material-ui/core/Stack';
import { Alert } from '@material-ui/core';

import { Button1 } from '../../components/Forms/FormItems';

import InputMask from 'react-input-mask';

const AddBuyer = (props) => {
  const history = useHistory();

  const [nomeEmpresa2, setNomeEmpresa] = useState('');
  const [email2, setEmail] = useState('');
  // const [telefone11, setTelefone1] = useState('');
  // const [telefone21, setTelefone2] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  // const [cnpj1, setCnpj] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [cep, setCep] = useState('');

  function handleSave() {
    const cleanTelefone1 = telefone1.replace(/\D/g, '');
    const cleanTelefone2 = telefone2.replace(/\D/g, '');
    const cleanCnpj = cnpj.value.replace(/\D/g, '');

    fetch(`https://www.vetnaweb.com.br/apicw/Clientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        // id: 0,
        nomeEmpresa: nomeEmpresa.value,
        emailPrincipal: email.value,
        telefone1: cleanTelefone1,
        telefone2: cleanTelefone2,
        // cadastroem:
        bloqueadoem: null,
        idlicenca: 1,
        cnpj: cleanCnpj.value,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        cep: null,
        liberaparcial: null,
        aceiteTermos: null,
      }),
    }).then(() => {
      // history.goBack();
    });
  }

  const username = useForm({});
  const email = useForm({});
  const password = useForm({});
  const nomeEmpresa = useForm({});
  // const cnpj = useForm({});
  // const telefone1 = useForm({});
  const [telefone1, setTelefone1] = useState('');
  // const telefone2 = useForm({});
  const [telefone2, setTelefone2] = useState('');
  const [cnpj, setCnpj] = useState({ value: '' });

  const {
    signUp,
    loadingAuth,
    getMessageByErrorCode,
    setClick,
    click,
    // setLoadingAuth,
  } = React.useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    signUp(email.value, password.value, username.value).then((value) => {
      setClick(true);

      value && setError(` - ${value.code}`);
      value && setErrorMessage(getMessageByErrorCode(value.code));
    });
  }

  React.useEffect(() => {
    setError(null);
    setClick(false);

    window.scrollTo(0, 0);

    return () => {
      setError(null);
      setClick(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const formatCnpj = (value) => {
  //   value = value.replace(/\D/g, '');
  //   value = value.slice(0, 14);
  //   value = value.replace(
  //     /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
  //     '$1.$2.$3/$4-$5',
  //   );
  //   return value;
  // };

  const formatCnpjWhileTyping = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length <= 2) {
      return value;
    } else if (value.length <= 5) {
      return `${value.slice(0, 2)}.${value.slice(2)}`;
    } else if (value.length <= 8) {
      return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5)}`;
    } else if (value.length <= 12) {
      return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
        5,
        8,
      )}/${value.slice(8)}`;
    } else {
      return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(
        5,
        8,
      )}/${value.slice(8, 12)}-${value.slice(12, 14)}`;
    }
  };

  const formatPhoneNumberWhileTyping = (value) => {
    const onlyNumbers = value.replace(/\D/g, '');
    let formattedValue = '';

    if (onlyNumbers.length <= 2) {
      formattedValue = onlyNumbers;
    } else if (onlyNumbers.length <= 7) {
      formattedValue = `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(2)}`;
    } else if (onlyNumbers.length <= 11) {
      formattedValue = `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
        2,
        7,
      )}-${onlyNumbers.slice(7)}`;
    } else {
      formattedValue = `(${onlyNumbers.slice(0, 2)}) ${onlyNumbers.slice(
        2,
        7,
      )}-${onlyNumbers.slice(7, 11)}`;
    }

    return formattedValue;
  };

  return (
    <section className="animeLeft">
      <h1
        style={{
          whiteSpace: 'nowrap',
          marginBottom: '40px',
          marginLeft: '-40%',
          fontWeight: 'bold',
          fontFamily: 'var(--type-second)',
        }}
      >
        Cadastre-se para começar a usar o Cota Na Web
      </h1>
      <h2
        style={{
          whiteSpace: 'nowrap',
          marginBottom: '40px',
          marginLeft: '-50%',
          fontWeight: 'bold',
          fontFamily: 'var(--type-second)',
          marginTop: '-20px',
        }}
      >
        Faça suas cotações e obtenha o melhor custo dos seus fornecedores...
      </h2>
      <h3
        style={{
          whiteSpace: 'nowrap',
          marginBottom: '40px',
          marginLeft: '-50%',
          fontWeight: 'bold',
          fontFamily: 'var(--type-second)',
          marginTop: '40px',
        }}
      >
        Preencha os dados a seguir:
      </h3>
      <form
        onSubmit={handleSubmit}
        onChange={() => {
          setClick(false);
          setErrorMessage(null);
          setError(null);
        }}
      >
        <Input label="Usuário" type="text" name="username" {...username} />
        <Input
          label="Empresa"
          type="text"
          name="nomeEmpresa"
          {...nomeEmpresa}
        />
        <Input label="E-mail" type="email" name="email" {...email} />
        <Input label="Senha" type="password" name="password" {...password} />
        {/* <Input label="CNPJ" type="text" name="cnpj" {...cnpj} /> */}
        <Input
          label="CNPJ"
          type="text"
          name="cnpj"
          value={formatCnpjWhileTyping(cnpj.value)}
          onChange={(e) => setCnpj({ value: e.target.value })}
          maxLength={18}
        />
        {/* <Input label="Telefone 1" type="text" name="telefone1" {...telefone1} /> */}
        <Input
          label="Telefone 1"
          type="tel"
          name="telefone1"
          value={formatPhoneNumberWhileTyping(telefone1)}
          onChange={(e) => {
            setTelefone1(e.target.value);
          }}
          maxLength={15}
        />
        {/* <Input label="Telefone 2" type="text" name="telefone2" {...telefone2} /> */}
        <Input
          label="Telefone 2"
          type="tel"
          name="telefone2"
          value={formatPhoneNumberWhileTyping(telefone2)}
          onChange={(e) => {
            setTelefone2(e.target.value);
          }}
          maxLength={15}
        />
        {loadingAuth ? (
          <Button1 variant="success" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Carregando...
            </Stack>
          </Button1>
        ) : (
          <Button1 onClick={handleSave}>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '16px',
              }}
            >
              Cadastrar
            </Stack>
          </Button1>
        )}
      </form>
    </section>
  );
};

export default AddBuyer;
