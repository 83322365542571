import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';

const EditQuotationItens = (props) => {
  const { id } = useParams();

  const history = useHistory();

  const idCliente = props.location.state.idCliente;

  const [infoItem, setInfoItem] = useState([]);

  const [descricao, setDescricao] = useState('');
  const [codBarras, setCodBarras] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [unidade, setUnidade] = useState('');
  const [observacao, setObservacao] = useState('');

  const handleDescricao = (event) => {
    setDescricao(event.target.value);
  };

  const handleCodBarras = (event) => {
    setCodBarras(event.target.value);
  };

  const handleQuantidade = (event) => {
    setQuantidade(event.target.value);
  };

  const handleUnidade = (event) => {
    setUnidade(event.target.value);
  };

  const handleObservacao = (event) => {
    setObservacao(event.target.value);
  };

  useEffect(() => {
    async function infoItem() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/CotacaoItens/${id}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setInfoItem(data[0]);
          setDescricao(data[0].descricao);
          setCodBarras(data[0].codigoBarras);
          setQuantidade(data[0].quantidade);
          setUnidade(data[0].unidade);
          setObservacao(data[0].observacao);
        });
    }
    infoItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleEdit() {
    fetch(`https://www.vetnaweb.com.br/apicw/CotacaoItens`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
      },
      body: JSON.stringify({
        id: id,
        idCliente: idCliente,
        idCotacao: infoItem.idCotacao,
        descricao: descricao,
        codigoBarras: codBarras,
        quantidade: quantidade,
        unidade: unidade,
        observacao: observacao,
      }),
    }).then(() => {
      history.goBack();
    });
  }

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Editar Item da Cotação
      </h1>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100%',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginRight: '5%',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            {' '}
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Descrição:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={descricao}
              onChange={handleDescricao}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Código de Barras:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={codBarras}
              onChange={handleCodBarras}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Quantidade:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={quantidade}
              onChange={handleQuantidade}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Unidade:{' '}
            </p>
            <input
              style={{
                marginLeft: '5px',
                width: '250px',
                borderRadius: '5px',
                textAlign: 'left',
                paddingLeft: '10px',
              }}
              type="text"
              value={unidade}
              onChange={handleUnidade}
            />
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '20px',
            }}
          >
            <p
              style={{ fontSize: '16px', fontWeight: 'bold', color: '#0d214f' }}
            >
              Observação:{' '}
            </p>
            <textarea
              style={{
                marginTop: '5px',
                borderRadius: '5px',
                paddingLeft: '10px',
                paddingTop: '5px',
                marginLeft: '10px',
              }}
              name="observacao"
              id="observacao"
              cols="26"
              rows="5"
              value={observacao}
              onChange={handleObservacao}
            ></textarea>
          </div>
        </div>
        <div style={{ margin: '30px auto' }}>
          <Button1
            style={{
              display: 'inline-block',
              width: '47%',
            }}
            onClick={() => history.goBack()}
          >
            Cancelar
          </Button1>
          <Button1
            style={{
              display: 'inline-block',
              marginLeft: '10px',
              width: '47%',
            }}
            onClick={handleEdit}
          >
            Salvar
          </Button1>{' '}
        </div>
      </div>
    </div>
  );
};

export default EditQuotationItens;
