import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Button1 } from '../../components/Forms/FormItems';
import { AuthContext } from '../../contexts/AuthContext';
import Error from '../../helper/Error';
import api from '../../services/api';

import './SupplierRegistration.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus } from '@fortawesome/free-solid-svg-icons';

const SupplierRegistration = (props) => {
  const history = useHistory();

  const [infoSupplier, setInfoSupplier] = useState([]);
  const { user, getMessageByErrorCodeAxios, getMessageError, verifyUserLoged } =
    React.useContext(AuthContext);

  const [clinicalList, setClinicalList] = React.useState([{}]);
  const [carregado, setCarregado] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [condition, setCondition] = useState(0);
  const [cotacaoFornecedor, setCotacaoFornecedor] = useState([]);

  const [opcoesAbertas, setOpcoesAbertas] = useState({});

  const toggleOpcoes = (index) => {
    const novasOpcoesAbertas = {};

    Object.keys(opcoesAbertas).forEach((key) => {
      novasOpcoesAbertas[key] = false;
    });

    novasOpcoesAbertas[index] = !opcoesAbertas[index];

    setOpcoesAbertas(novasOpcoesAbertas);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const tabelaElement = document.getElementById('tableQuotation');
      if (tabelaElement && !tabelaElement.contains(event.target)) {
        setOpcoesAbertas({});
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    verifyUserLoged().then((response) => {
      response &&
        api
          .get(
            'https://www.vetnaweb.com.br/apicw/Validacao?email=' +
              `${user.email}`,
          )
          .then((response) => {
            setClinicalList(response.data);
            setCarregado(true);
          })
          .catch((error) => {
            setError(
              `${error.response.status} - ${getMessageError(
                error.response.status,
              )}`,
            );
            setErrorMessage(getMessageByErrorCodeAxios(error.response.status));
            // eslint-disable-next-line eqeqeq
            setCondition(condition == 0 ? 1 : 0);
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function supplierData() {
      // eslint-disable-next-line no-unused-vars
      const response = await fetch(
        `https://www.vetnaweb.com.br/apicw/FornecedorCotacao?email=${user.email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          setCotacaoFornecedor(data);
        });
    }
    supplierData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicalList]);

  const idAcesso = clinicalList.idAcesso;

  useEffect(() => {
    if (carregado) {
      async function supplier() {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch(
          `https://www.vetnaweb.com.br/apicw/Fornecedor?idParceiro=${idAcesso}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Basic d2hhdHN2ZXQ6dmV0Y29udHJvbG1hc3Rlcg==',
            },
          },
        )
          .then((response) => response.json())
          .then((data) => {
            setInfoSupplier(data);
          });
      }
      supplier();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carregado]);

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleRowEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  return (
    <div>
      <h1
        style={{
          textAlign: 'center',
          marginTop: '20px',
          marginBottom: '40px',
          color: '#0d214f',
        }}
      >
        Fornecedores
        <Link
          to={{
            pathname: '/AdicionarFornecedor',
            state: {
              idAcesso: idAcesso,
            },
          }}
          style={{
            display: 'inline-block',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              height: 'max-content',
              alignSelf: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size={12}
              style={{
                marginBottom: '5px',
                marginLeft: '20px',
                color: '#0d214f',
                fontSize: '24',
              }}
            />
          </div>
        </Link>
      </h1>
      <div style={{ paddingBottom: '5%' }}>
        <table className="estilizada">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Representante</th>
              <th>E-mail</th>
              <th>WhatsApp</th>
              <th>Grupo</th>
            </tr>
          </thead>
          <tbody>
            {infoSupplier.map((info, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index === hoveredRow ? '#DFE9F5' : 'white',
                }}
                onMouseEnter={() => handleRowEnter(index)}
                onMouseLeave={handleRowLeave}
              >
                <td
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.empresa}
                </td>
                <td
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.representante}
                </td>
                <td
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.email}
                </td>
                <td
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.whatsapp}
                </td>
                <td
                  onClick={() => toggleOpcoes(index)}
                  style={{ cursor: 'pointer' }}
                >
                  {info.grupo}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {' '}
                  <FontAwesomeIcon
                    onClick={() => toggleOpcoes(index)}
                    style={{ cursor: 'pointer' }}
                    icon={faEllipsisV}
                  />
                </td>
                {opcoesAbertas[index] && (
                  <ul
                    style={{
                      marginTop: '30px',
                      marginLeft: '-47px',
                      padding: '10px',
                      borderRadius: '4px',
                      position: 'absolute',
                      zIndex: '999',
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                    }}
                  >
                    <li
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        history.push(`/EditarFornecedor/${info.id}`, {
                          idAcesso: idAcesso,
                        });
                      }}
                    >
                      Editar
                    </li>
                  </ul>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierRegistration;
