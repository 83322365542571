import React, { useState, useEffect } from 'react';
import { Button1 } from '../../components/Forms/FormItems';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import api from '../../services/api';

const ExamPage = (props) => {
  const history = useHistory();
  const location = useLocation();

  const savedData = JSON.parse(localStorage.getItem('dadosTutor'));

  const nomeTutor2 = savedData?.nomeTutor;
  const cpf2 = savedData?.cpf;
  const nascimentoTutor2 = savedData?.nascimentoTutor;
  const email2 = savedData?.email;
  const telefone2 = savedData?.telefone;

  const savedData2 = JSON.parse(localStorage.getItem('dadosPet'));

  const especie2 = savedData2?.especie;
  const genero2 = savedData2?.genero;
  const nascimentoPet2 = savedData2?.nascimentoPet;
  const nomePet2 = savedData2?.nomePet;
  const pelagem2 = savedData2?.pelagem;
  const peso2 = savedData2?.peso;
  const porte2 = savedData2?.porte;
  const raca2 = savedData2?.raca;
  const unidade2 = savedData2?.unidade;

  const savedData3 = JSON.parse(localStorage.getItem('dadosRequisitante'));

  const nomeSolicitante2 = savedData3?.nomeSolicitante;
  const crmv2 = savedData3?.crmv;
  const email3 = savedData3?.email;

  const savedData4 = JSON.parse(localStorage.getItem('dadosExame'));

  const idRecipiente2 = savedData4?.idRecipiente;
  const laudoSolicitado2 = savedData4?.laudoSolicitado;
  const siglaLaudo2 = savedData4?.siglaLaudo;

  const [idRecipiente, setIdRecipiente] = useState(idRecipiente2 || '');
  const [siglaLaudo, setSiglaLaudo] = useState(siglaLaudo2 || '');
  const [laudoSolicitado, setLaudoSolicitado] = useState(
    laudoSolicitado2 || '',
  );

  const handleIdRecipiente = (event) => {
    setIdRecipiente(event.target.value);
  };

  const handleLaudoSolicitado = (event) => {
    const selectedValue = event.target.value;
    const [sigla, exame] = selectedValue.split('|');

    setLaudoSolicitado(selectedValue);
    setSiglaLaudo(sigla);
  };

  const [reports, setReports] = useState([]);

  useEffect(() => {
    async function sizesData() {
      try {
        const response = await api.get(`/TabelaExames`);
        setReports(response.data);
      } catch (error) {}
    }
    sizesData();
  }, []);

  function handleBack() {
    const dateToSave = {
      idRecipiente,
      siglaLaudo,
      laudoSolicitado,
    };

    const jsonData = JSON.stringify(dateToSave);

    localStorage.setItem('dadosExame', jsonData);

    history.goBack();
  }

  async function handleSave() {
    try {
      const response = await api.post('/Exames', {
        id: 0,
        idParceiro: 1,
        tutor_nome: nomeTutor2,
        tutor_documento: cpf2,
        tutor_data_nascimento: nascimentoTutor2,
        tutor_email: email2,
        tutor_telefone: telefone2,
        pet_nome: nomePet2,
        pet_data_nascimento: nascimentoPet2,
        pet_genero: genero2,
        pet_especie: especie2,
        pet_raca: raca2,
        pet_pelagem: pelagem2,
        pet_porte: porte2,
        pet_peso_valor: peso2,
        pet_peso_unidade: unidade2,
        solicitante_veterinario_nome: nomeSolicitante2,
        solicitante_veterinario_cfmv: crmv2,
        solicitante_email: email3,
        recipiente: idRecipiente,
        exame_codigo: siglaLaudo,
        exame_descricao: laudoSolicitado,
      });

      const primeiroPostID = response.data;

      const segundaResponse = await api.post('/ExamesItens', {
        id: 0,
        id_exame: primeiroPostID,
        recipiente: idRecipiente,
        exame_codigo: siglaLaudo,
        exame_descricao: laudoSolicitado,
        statusEnvio: null,
      });
      localStorage.removeItem('dadosTutor');
      localStorage.removeItem('dadosPet');
      localStorage.removeItem('dadosRequisitante');
      localStorage.removeItem('dadosExame');
      history.push(`/`);
    } catch (error) {}
  }

  return (
    <div>
      <h2
        style={{
          textAlign: 'center',
          marginTop: '30px',
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Preencha a Solicitação de Exame
      </h2>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>Exame</h3>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '10px', borderRadius: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              ID do Recipiente:
            </p>
            <input
              type="text"
              value={idRecipiente}
              onChange={handleIdRecipiente}
              style={{ width: '500px' }}
            />
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p
              style={{
                fontWeight: 'bold',
                marginRight: '10px',
                width: '100px',
              }}
            >
              Laudo Solicitado:
            </p>
            <select
              name=""
              id=""
              style={{ width: '500px' }}
              onChange={handleLaudoSolicitado}
              value={laudoSolicitado}
            >
              <option value="">Selecione o laudo...</option>
              {reports.map((report) => (
                <option value={`${report.sigla}|${report.exame}`}>
                  {report.exame}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button1
          style={{
            marginBottom: '5px',
            marginRight: '10px',
            marginTop: '40px',
          }}
          onClick={handleBack}
        >
          Voltar
        </Button1>
        <Button1
          style={{
            marginBottom: '5px',
            marginTop: '40px',
          }}
          onClick={handleSave}
        >
          Salvar
        </Button1>
      </div>
    </div>
  );
};

export default ExamPage;
